import code0007100001 from '../images/code0007100001.png';
import code72e15b4b from '../images/code72e15b4b.png';

export const setA = [
    {
        'id':2001,
        'question':`What service is for Accessing data frequently and/or storage for brief periods?`,
        'answers': [
            {'id':0, 'desc':'Standard Storage'},
            {'id':1, 'desc':'Nearline Storage'},
            {'id':2, 'desc':'Coldline Storage'},
            {'id':3, 'desc':'Archive Storage'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':'Standard Storage is for Frequent Access ("hot" data) and/or brief storage'},
            {'id':1, 'desc':'Nearline Storage is Read/modify data ≤ once per month'},
            {'id':2, 'desc':'Coldline Storage Read/modify data no more than once a quarter'},
            {'id':3, 'desc':'Archive Storage Read/modify data < once a year'},
        ]
    },
    {
        'id':2002,
        'question':`What is the Minimum duration for Archive Storage?`,
        'answers': [
            {'id':0, 'desc':'None'},
            {'id':1, 'desc':'30 Days'},
            {'id':2, 'desc':'90 Days'},
            {'id':3, 'desc':'365 Days'},
        ],
        'answerId': [3],
        'rationale': [
            {'id':3, 'desc':'Archive Storage Read/modify data < once a year, with a minium duration of 365 days.'},
        ]
    },
    {
        'id':2003,
        'question':`A client has data that they need to access less than once a month. What storage is best?`,
        'answers': [
            {'id':0, 'desc':'Standard Storage'},
            {'id':1, 'desc':'Nearline Storage'},
            {'id':2, 'desc':'Coldline Storage'},
            {'id':3, 'desc':'Archive Storage'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':'Nearline storage allows for read/modify data ≤ once per month'},
        ]
    },
    {
        'id':2004,
        'question':`Your developers want a compute option that allows them to develop for web and mobile applications. The team knows Java and Go. What is the best Compute Option?`,
        'answers': [
            {'id':0, 'desc':'Compute Engine IaaS'},
            {'id':1, 'desc':'Google Kubernetes Engine Hybrid'},
            {'id':2, 'desc':'App Engine PaaS'},
            {'id':3, 'desc':'Serverless Cloud Functions'},
        ],
        'answerId': [2],
        'rationale': [
            {'id':3, 'desc':'App Engine PaaS is for web and mobile applications and supports Java and Go languages.'},
            {'id':0, 'desc':'Compute engine is for General computing workloads. It does not fit the web and mobile requirement.'},
            {'id':1, 'desc':'GKE is for Container based workloads. It does not fit the web and mobile requirement.'},
            {'id':2, 'desc':'Cloud functions are for short lived code responding to events, not a good fit for hosting application code.'},
        ]
    },
    {
        'id':2005,
        'question':`Unmanaged Instance Groups`,
        'answers': [
            {'id':0, 'desc':'Collect different kinds of instances. Usually this is done for management of "lift and shift" existing designs, and it is not recommended because it does not make the best use of the features available in cloud.'},
            {'id':1, 'desc':'Are all the same kind of instance, meaning that the type can be defined by an Instance Template and Autoscaling is available'},
        ],
        'answerId': [0],
        'rationale': []
    },
    {
        'id':2006,
        'question':`Zonal and Regional Instance Groups are examples of`,
        'answers': [
            {'id':0, 'desc':'Unmanaged Instance Groups'},
            {'id':1, 'desc':'Managed Instance Groups'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':'Zonal and Regional Instance Groups are types of Managed Instance Groups'},
            {'id':0, 'desc':'Unmanaged Instance Groups colled different types of instances and are not managed or templated.'},
        ]
    },
    {
        'id':2007,
        'question':`Use ______ to keep all the instances in the same zone, and provide consistent network location when the instances must communicate with similar latency and avoid zone-to-zone transfer`,
        'answers': [
            {'id':0, 'desc':'Unmanaged Instance Groups'},
            {'id':1, 'desc':'Zonal Managed Instance Groups'},
            {'id':2, 'desc':'Regional Managed Instance Groups'},
            {'id':3, 'desc':'Managed Network Instance Groups'},
        ],
        'answerId': [1],
        'rationale': []
    },
    {
        'id':2008,
        'question':`Autoscaling works with all types of Instance Groups`,
        'answers': [
            {'id':0, 'desc':'True'},
            {'id':1, 'desc':'False'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':'Autoscaling works with managed instance groups (MIGs) only. Unmanaged instance groups are not supported.'},
        ]
    },
    {
        'id':2009,
        'question':`Small stateless servers`,
        'answers': [
            {'id':0, 'desc':'Increase reliability and scalability'},
            {'id':1, 'desc':'Reduce complexity and latency'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':'Small stateless servers increase reliability and scalability'},
            {'id':1, 'desc':'Large stateful servers reduce complexity and latency'},
        ]
    },
    {
        'id':2010,
        'question':`If you want visiblity to servers, automation and to eliminate overhead, you should use`,
        'answers': [
            {'id':0, 'desc':'A Managed Service'},
            {'id':1, 'desc':'A Serverless Service'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':'A Managed Service gives you visibility to servers but limited control. You give up control for automation. Great for popular use-cases and eliminating overhead.'},
            {'id':1, 'desc':'Serverless Services completely hide all servers. Generally, more fast, scalable, and efficient than you could create on your own.'},
        ]
    },
    {
        'id':2011,
        'question':`The most important control over resource consumption and costs is writing a query that controls the amount of data processed. In general, this is done with..`,
        'answers': [
            {'id':0, 'desc':'A LIMIT which omits the data from the final results at the end of the job'},
            {'id':1, 'desc':'A SELECT by choosing subsets of data at the start of a job'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':'SELECT by choosing subsets of data at the start of a job rather than by using LIMIT which only omits data from the final results at the end of a job'},
        ]
    },
    {
        'id':2012,
        'question':`A fully managed service for managing devices. This includes registration, authentication, and authorization inside the Google Cloud resource hierarchy as well as device metadata stored in the cloud, and the ability to send device configuration from the service to devices.`,
        'answers': [
            {'id':0, 'desc':'IoT Core'},
            {'id':1, 'desc':'Pub/Sub'},
            {'id':2, 'desc':'Dataflow'},
            {'id':3, 'desc':'Cloud Functions'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':1, 'desc':'IoT Core provides a fully managed service for managing devices. This includes registration, authentication, and authorization inside the Google Cloud resource hierarchy as well as device metadata stored in the cloud, and the ability to send device configuration from the service to devices.'},
        ]
    },
    {
        'id':2013,
        'question':`You need high-volume data processing pipelines for IoT - what service would fit your needs?`,
        'answers': [
            {'id':0, 'desc':'Dataflow'},
            {'id':1, 'desc':'Pub/Sub'},
            {'id':2, 'desc':'IoT Core Device Manager'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':'Dataflow provides the open Apache Beam programming model as a managed service for processing data in multiple ways, including batch operations, extract-transform-load (ETL) patterns, and continuous, streaming computation. Dataflow can be particularly useful for managing the high-volume data processing pipelines required for IoT scenarios. Dataflow is also designed to integrate seamlessly with the other Google Cloud services you choose for your pipeline.'},
        ]
    },
    {
        'id':2014,
        'question':`Which network feature could help a company meet its goals to expand service to Asia while reducing latency?`,
        'answers': [
            {'id':0, 'desc':'Cloud NAT'},
            {'id':1, 'desc':'NetworkTCP/UDP'},
            {'id':2, 'desc':'Cloud Router'},
            {'id':3, 'desc':'Cloud CDN'},
        ],
        'answerId': [3],
        'rationale': [
            {'id':3, 'desc':`Cloud CDN will enable a company to expand its online presence with a single IP address and global reach leveraging Google's global network`},
            {'id':0, 'desc':`Cloud NAT enables you to provision your application instances without public IP addresses, while also allowing them to access the internet, but it won’t reduce the latency associated with web and video content delivery, with global scale and reach.`},
            {'id':1, 'desc':`Network load balancing is internal only. It can help scale the service but not necessarily expand its reach.`},
            {'id':2, 'desc':`Cloud Router discovers changed network topology using BGP. That might be valuable if they open offices in Asia, but that doesn't directly expand the services.`},
        ]
    },
    {
        'id':2015,
        'question':`You are running a cluster on Kubernetes Engine to serve a web application. Users are reporting that a specific part of the application is not responding anymore. You notice that all pods of your deployment keep restarting after 2 seconds. The application writes logs to standard output. You want to inspect the logs to find the cause of the issue. Which approach can you take?`,
        'answers': [
            {'id':0, 'desc':'Review the Stackdriver logs for each Compute Engine instance that is serving as a node in the cluster.'},
            {'id':1, 'desc':'Review the Stackdriver logs for the specific Kubernetes Engine container that is serving the unresponsive part of the application.'},
            {'id':2, 'desc':'Connect to the cluster using gcloud credentials and connect to a container in one of the pods to read the logs.'},
            {'id':3, 'desc':'Review the Serial Port logs for each Compute Engine instance that is serving as a node in the cluster'},
        ],
        'answerId': [2],
        'rationale': []
    },
    {
        'id':2016,
        'question':`Your customer support tool logs all email and chat conversations to Cloud Bigtable for retention and analysis. What is the recommended approach for sanitizing this data of personally identifiable information or payment card information before initial storage?`,
        'answers': [
            {'id':0, 'desc':'Hash all data using SHA256'},
            {'id':1, 'desc':'Encrypt all data using elliptic curve cryptography'},
            {'id':2, 'desc':'De-identify the data with the Cloud Data Loss Prevention API'},
            {'id':3, 'desc':'Use regular expressions to find and redact phone numbers, email addresses, and credit card numbers'},
        ],
        'answerId': [2],
        'rationale': [{'id':2,'desc':`De-identify the data with the Cloud Data Loss Prevention API.

        The Cloud Data Loss Prevention (DLP) API is a powerful tool that allows you to automatically discover, classify, and redact sensitive data in your organization. It uses advanced machine learning techniques to accurately identify and protect a wide range of sensitive data types, including personal information such as names, addresses, phone numbers, and payment card information.
        
        Using the DLP API to de-identify your data before storing it in Cloud Bigtable is the most effective way to ensure that sensitive information is protected and not accessible to unauthorized users.`}]
    }
];

// current test
export const setB = [
    {
        'id':2200,
        'question':`Your company has decided to make a major revision of their API in order to create better experiences for their developers. They need to keep the old version of the API available and deployable, while allowing new customers and testers to try out the new API. They want to keep the same SSL and DNS records in place to serve both APIs.
        What should they do?`,
        'answers': [
            {'id':0, 'desc':'Configure a new load balancer for the new version of the API'},
            {'id':1, 'desc':'Reconfigure old clients to use a new endpoint for the new API'},
            {'id':2, 'desc':'Have the old API forward traffic to the new API based on the path'},
            {'id':3, 'desc':'Use separate backend pools for each API path behind the load balancer'},
        ],
        'answerId': [3],
        'rationale': [{'id':3, 'desc':'HTTP(S) load balancer can direct traffic reaching a single IP to different backends based on the incoming URL'}]
    },
    {
        'id':2201,
        'question':`Your company plans to migrate a multi-petabyte data set to the cloud. The data set must be available 24hrs a day. Your business analysts have experience only with using a SQL interface.
        How should you store the data to optimize it for ease of analysis?`,
        'answers': [
            {'id':0, 'desc':'Load data into Google BigQuery'},
            {'id':1, 'desc':'Insert data into Google Cloud SQL'},
            {'id':2, 'desc':'Put flat files into Google Cloud Storage'},
            {'id':3, 'desc':'Stream data into Google Cloud Datastore'},
        ],
        'answerId': [0],
        'rationale': [{'id':0, 'desc':`BigQuery is Google\'s server-less, highly scalable, low cost enterprise data warehouse designed to makk all your data analysts productive. 
        Because there is no infrastructure to manage, you can focus on analyzing data to find meaningful insights using familiar SQL and you don't need a database administrator.`}]
    },
    {
        'id':2202,
        'question':`The operations manager asks you for a list of recommended practices that she should consider when migrating a J2EE application to the cloud.
        Which three practices should you recommend?`,
        'answers': [
            {'id':0, 'desc':'Port the application code to run on Google App Engine'},
            {'id':1, 'desc':'Integrate Cloud Dataflow into the application to capture real-time metrics'},
            {'id':2, 'desc':'Instrument the application with a monitoring tool like Stackdriver Debugger'},
            {'id':3, 'desc':'Select an automation framework to reliably provision the cloud infrastructure'},
            {'id':4, 'desc':'Deploy a continuous integration tool with automated testing in a staging environment'},
            {'id':5, 'desc':'Migrate from MySQL to a managed NoSQL database like Google Cloud Datastore or Bigtable'},
        ],
        'answerId': [0,2,4],
        'rationale': [{'id':0, 'desc':``}]
    },
    {
        'id':2203,
        'question':`A news feed web service has the following code running on Google App Engine. During peak load, users report that they can see news articles they already viewed.
        What is the most likely cause of this problem?`,
        'img':code0007100001,
        'answers': [
            {'id':0, 'desc':'The session variable is local to just a single instance'},
            {'id':1, 'desc':'The session variable is being overwritten in Cloud Datastore'},
            {'id':2, 'desc':' The URL of the API needs to be modified to prevent caching'},
            {'id':3, 'desc':'The HTTP Expires header needs to be set to -1 stop caching'},
        ],
        'answerId': [0],
        'rationale': []
    },
    {
        'id':2204,
        'question':`An application development team believes their current logging tool will not meet their needs for their new cloud-based product. They want a better tool to capture errors and help them analyze their historical log data. You want to help them find a solution that meets their needs.
        What should you do?`,
        'answers': [
            {'id':0, 'desc':'Direct them to download and install the Google StackDriver logging agent'},
            {'id':1, 'desc':'Send them a list of online resources about logging best practices'},
            {'id':2, 'desc':'Help them define their requirements and assess viable logging tools'},
            {'id':3, 'desc':'Help them upgrade their current tool to take advantage of any new features'},
        ],
        'answerId': [2],
        'rationale': []
    },
    {
        'id':2205,
        'question':`You need to reduce the number of unplanned rollbacks of erroneous production deployments in your company's web hosting platform. Improvement to the QA/
        Test processes accomplished an 80% reduction.
        Which additional two approaches can you take to further reduce the rollbacks?`,
        'answers': [
            {'id':0, 'desc':'Fragment the monolithic platform into microservices'},
            {'id':1, 'desc':'Replace the QA environment with canary releases'},
            {'id':2, 'desc':'Reduce the platform\'s dependency on relational database systems'},
            {'id':3, 'desc':'Replace the platform\'s relational database systems with a NoSQL database'},
            {'id':4, 'desc':'Introduce a green-blue deployment model'},
        ],
        'answerId': [0,4],
        'rationale': [{'id': 0, 'desc':'In software, a canary process is usually the first instance that receives live production traffic about a new configuration update, either a binary or configuration rollout. The new release only goes to the canary at first. The fact that the canary handles real user traffic is key: if it breaks, real users get affected, so canarying should be the first step in your deployment process, as opposed to the last step in testing in production.'}]
    },
    {
        'id':2206,
        'question':`To reduce costs, the Director of Engineering has required all developers to move their development infrastructure resources from on-premises virtual machines
        (VMs) to Google Cloud Platform. These resources go through multiple start/stop events during the day and require state to persist. You have been asked to design the process of running a development environment in Google Cloud while providing cost visibility to the finance department.
        Which two steps should you take?`,
        'answers': [
            {'id':0, 'desc':'Use the - -no-auto-delete flag on all persistent disks and stop the VM'},
            {'id':1, 'desc':'Use the - -auto-delete flag on all persistent disks and terminate the VM'},
            {'id':2, 'desc':'Store all state into local SSD, snapshot the persistent disks, and terminate the VM'},
            {'id':3, 'desc':'Store all state in Google Cloud Storage, snapshot the persistent disks, and terminate the VM'},
            {'id':4, 'desc':'Use Google BigQuery billing export and labels to associate cost to groups'},
        ],
        'answerId': [0,4],
        'rationale': [{'id':0, 'desc':`Use of persistent disk mean the data is preserved even after restart. -np-auto-delete on persistent disk means pesistent disk won't be deleted when VM is deleted.`}]
    },
    {
        'id':2208,
        'question':`Your company wants to track whether someone is present in a meeting room reserved for a scheduled meeting. There are 1000 meeting rooms across 5 offices on 3 continents. Each room is equipped with a motion sensor that reports its status every second. The data from the motion detector includes only a sensor ID and several different discrete items of information. Analysts will use this data, together with information about account owners and office locations.
        Which database type should you use?`,
        'answers': [
            {'id':0, 'desc':'Flat file'},
            {'id':1, 'desc':'NoSQL'},
            {'id':2, 'desc':'Relational'},
            {'id':3, 'desc':'Blobstore'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':`Relational databases were not designed to cope with the scale and agility challenges that face modern applications, nor were they built to take advantage of the commodity storage and processing power available today.`}
        ]
    },
    {
        'id':2209,
        'question':`You set up an autoscaling instance group to serve web traffic for an upcoming launch. After configuring the instance group as a backend service to an HTTP(S) load balancer, you notice that virtual machine (VM) instances are being terminated and re-launched every minute. The instances do not have a public IP address.
        You have verified the appropriate web response is coming from each instance using the curl command. You want to ensure the backend is configured correctly.
        What should you do?
        `,
        'answers': [
            {'id':0, 'desc':'Ensure that a firewall rules exists to allow source traffic on HTTP/HTTPS to reach the load balancer.'},
            {'id':1, 'desc':'Assign a public IP to each instance and configure a firewall rule to allow the load balancer to reach the instance public IP.'},
            {'id':2, 'desc':'Ensure that a firewall rule exists to allow load balancer health checks to reach the instances in the instance group.'},
            {'id':3, 'desc':'Create a tag on each instance with the name of the load balancer. Configure a firewall rule with the name of the load balancer as the source and the instance tag as the destination.'},
        ],
        'answerId': [2],
        'rationale': [{'id':2, 'desc':`The best practice when configuration a health check is to check health and serve traffic on the same port. However, it is possible to perform health checks on one port, but serve traffic on another. If you do use two different ports, ensure that firewall rules and services running on instances are configured appropriately. If you run health checks and serve traffic on the same port, but decide to switch ports at some point, be sure to update both the backend service and the health check.
        Backend services that do not have a valid global forwarding rule referencing it will not be health checked and will have no health status.
        Reference:
        https://cloud.google.com/compute/docs/load-balancing/http/backend-service`}]
    },
    {
        'id':2210,
        'question':`You write a Python script to connect to Google BigQuery from a Google Compute Engine virtual machine. The script is printing errors that it cannot connect to
        BigQuery.
        What should you do to fix the script?`,
        'answers': [
            {'id':0, 'desc':'Install the latest BigQuery API client library for Python'},
            {'id':1, 'desc':'Run your script on a new virtual machine with the BigQuery access scope enabled'},
            {'id':2, 'desc':'Create a new service account with BigQuery access and execute your script with that user'},
            {'id':3, 'desc':'Install the bq component for gcloud with the command gcloud components install bq.'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':``}
        ]
    },
    {
        'id':2211,
        'question':`Your customer is moving an existing corporate application to Google Cloud Platform from an on-premises data center. The business owners require minimal user disruption. There are strict security team requirements for storing passwords.
        What authentication strategy should they use?`,
        'answers': [
            {'id':0, 'desc':'Use G Suite Password Sync to replicate passwords into Google'},
            {'id':1, 'desc':'Federate authentication via SAML 2.0 to the existing Identity Provider'},
            {'id':2, 'desc':'Provision users in Google using the Google Cloud Directory Sync tool'},
            {'id':3, 'desc':'Ask users to set their Google password to match their corporate password'},
        ],
        'answerId': [2],
        'rationale': [
            {'id':2, 'desc':`The global Directory is available to both Cloud Platform and G Suite resources and can be provisioned by a number of means. Provisioned users can take advantage of rich authentication features including single sign-on (SSO), OAuth, and two-factor verification.
            You can provision users automatically using one of the following tools and services:
            Google Cloud Directory Sync (GCDS)`}
        ]
    },
    {
        'id':2212,
        'question':`Your company has successfully migrated to the cloud and wants to analyze their data stream to optimize operations. They do not have any existing code for this analysis, so they are exploring all their options. These options include a mix of batch and stream processing, as they are running some hourly jobs and live processing some data as it comes in.
        Which technology should they use for this?`,
        'answers': [
            {'id':0, 'desc':'Google Cloud Dataproc'},
            {'id':1, 'desc':'Google Cloud Dataflow'},
            {'id':2, 'desc':'Google Container Engine with Bigtable'},
            {'id':3, 'desc':'Google Compute Engine with Google BigQuery'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':`Cloud Dataflow is a fully-managed service for transforming and enriching data in stream (real time) and batch (historical) modes with equal reliability and expressiveness -- no more complex workarounds or compromises needed.`}
        ]
    },
    {
        'id':2213,
        'question':`Your customer is receiving reports that their recently updated Google App Engine application is taking approximately 30 seconds to load for some of their users.
        This behavior was not reported before the update.
        What strategy should you take?`,
        'answers': [
            {'id':0, 'desc':'Work with your ISP to diagnose the problem'},
            {'id':1, 'desc':'Open a support ticket to ask for network capture and flow data to diagnose the problem, then roll back your application'},
            {'id':2, 'desc':'Roll back to an earlier known good release initially, then use Stackdriver Trace and Logging to diagnose the problem in a development/test/staging environment'},
            {'id':3, 'desc':'Roll back to an earlier known good release, then push the release again at a quieter period to investigate. Then use Stackdriver Trace and Logging to diagnose the problem'},
        ],
        'answerId': [2],
        'rationale': [
            {'id':2, 'desc':`Stackdriver Logging allows you to store, search, analyze, monitor, and alert on log data and events from Google Cloud Platform and Amazon Web Services
            (AWS). Our API also allows ingestion of any custom log data from any source. Stackdriver Logging is a fully managed service that performs at scale and can ingest application and system log data from thousands of VMs. Even better, you can analyze all that log data in real time.`}
        ]
    },
    {
        'id':2214,
        'question':`A production database virtual machine on Google Compute Engine has an ext4-formatted persistent disk for data files. The database is about to run out of storage space.
        How can you remediate the problem with the least amount of downtime?`,
        'answers': [
            {'id':0, 'desc':'In the Cloud Platform Console, increase the size of the persistent disk and use the resize2fs command in Linux.'},
            {'id':1, 'desc':'Shut down the virtual machine, use the Cloud Platform Console to increase the persistent disk size, then restart the virtual machine'},
            {'id':2, 'desc':'In the Cloud Platform Console, increase the size of the persistent disk and verify the new space is ready to use with the fdisk command in Linux'},
            {'id':3, 'desc':'In the Cloud Platform Console, create a new persistent disk attached to the virtual machine, format and mount it, and configure the database service to move the files to the new disk'},
            {'id':4, 'desc':'In the Cloud Platform Console, create a snapshot of the persistent disk restore the snapshot to a new larger disk, unmount the old disk, mount the new disk and restart the database service'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':`On Linux instances, connect to your instance and manually resize your partitions and file systems to use the additional disk space that you added.
            Extend the file system on the disk or the partition to use the added space. If you grew a partition on your disk, specify the partition. If your disk does not have a partition table, specify only the disk ID. sudo resize2fs /dev/[DISK_ID][PARTITION_NUMBER] where [DISK_ID] is the device name and [PARTITION_NUMBER] is the partition number for the device where you are resizing the file system.`}
        ]
    },
    {
        'id':2215,
        'question':`Your application needs to process credit card transactions. You want the smallest scope of Payment Card Industry (PCI) compliance without compromising the ability to analyze transactional data and trends relating to which payment methods are used.
        How should you design your architecture?`,
        'answers': [
            {'id':0, 'desc':'Create a tokenizer service and store only tokenized data'},
            {'id':1, 'desc':'Create separate projects that only process credit card data'},
            {'id':2, 'desc':'Create separate subnetworks and isolate the components that process credit card data'},
            {'id':3, 'desc':'Streamline the audit discovery phase by labeling all of the virtual machines (VMs) that process PCI data'},
            {'id':4, 'desc':'Enable Logging export to Google BigQuery and use ACLs and views to scope the data shared with the auditor'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':`https://www.sans.org/reading-room/whitepapers/compliance/ways-reduce-pci-dss-audit-scope-tokenizing-cardholder-data-33194`}
        ]
    },
    {
        'id':2216,
        'question':`True or False? Networks Isolate Systems`,
        'answers': [
            {'id':0, 'desc':'True'},
            {'id':1, 'desc':'False'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':``}
        ]
    }
];

export const setC = [
    {
        'id':2400,
        'question':`Company requires the data stored in BigQuery tables to be deleted after 3 years. The table will be used by reporting system and should not impacted. What will be your solution ?`,
        'answers': [
            {'id':0, 'desc':'Use BigQuery Dataset table expiration configuration'},
            {'id':1, 'desc':'Use Time partitioning for table and use partitioning expiration time configuration either at Dataset or at table'},
            {'id':2, 'desc':'Use Google Scheduler to delete old data'},
            {'id':3, 'desc':'Use table level expiration configuration'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':`https://cloud.google.com/bigquery/docs/best-practices-storage Configure the default table expiration for your datasets, configure the expiration time for your tables, and configure the partition expiration for partitioned tables.`}
        ]
    },
    {
        'id':2402,
        'question':`Your company has multiple on-premises systems that serve as sources for reporting. The data has not been maintained well and has become degraded over time.
        You want to use Google-recommended practices to detect anomalies in your company data. What should you do?`,
        'answers': [
            {'id':0, 'desc':'Upload your files into Cloud Storage. Use Cloud Dataprep to explore and clean your data.'},
            {'id':1, 'desc':'Connect Cloud Dataprep to your on-premises systems. Use Cloud Dataprep to explore and clean your data.'},
            {'id':2, 'desc':'Upload your files into Cloud Storage. Use Cloud Datalab to explore and clean your data'},
            {'id':3, 'desc':'Connect Cloud Datalab to your on-premises systems. Use Cloud Datalab to explore and clean your data.'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':'https://cloud.google.com/dataprep/docs/quickstarts/quickstart-dataprep Cloud Dataprep can take data from Cloud Storage'},
        ]
    },
    {
        'id':2403,
        'question':`You have deployed an application to Kubernetes Engine, and are using the Cloud SQL proxy container to make the Cloud SQL - MySQL database available to the services running on Kubernetes. You are notified that the application is reporting database connection issues. Your have asked to provide root cause analysis, What will be your solution?`,
        'answers':[
            {'id':0, 'desc':`Validate that the Service Account used by the Cloud SQL proxy container still has the Cloud Build Editor role.`},
            {'id':1, 'desc':`In the GCP Console, navigate to Stackdriver Logging. Consult logs for Kubernetes Engine and Cloud SQL.`},
            {'id':2, 'desc':`Use gcloud sql instances restart.`},
            {'id':3, 'desc':`In the GCP Console, navigate to Cloud SQL. Restore the latest backup. Use kubectl to restart all pods.`},
        ],
        'answerId':[0],
        'rationale': [
            {'id':0, 'desc':`You will need to check if there is any problems with permissions as the applications was running already and issues started`},
        ]
    },
    {
        'id':2404,
        'question':`You are working in a highly secured environment where public Internet access from the Compute Engine VMs is not allowed. You do not yet have a VPN connection to access an on-premises file server. You need to install specific software on a Compute Engine instance. How should you install the software?`,
        'answers': [
            {'id':0, 'desc':`Upload the required installation files to Cloud Storage and use firewall rules to block all traffic except the IP address range for Cloud Storage. Download the files to the VM using gsutil.`},
            {'id':1, 'desc':`Upload the required installation files to Cloud Source Repositories and use firewall rules to block all traffic except the IP address range for Cloud Source Repositories. Download the files to the VM using gsutil.`},
            {'id':2, 'desc':`Upload the required installation files to Cloud Source Repositories. Configure the VM on a subnet with a Private Google Access subnet. Assign only an internal IP address to the VM. Download the installation files to the VM using gcloud.`},
            {'id':3, 'desc':`Upload the required installation files to Cloud Storage. Configure the VM on a subnet with a Private Google Access subnet. Assign only an internal IP address to the VM. Download the installation files to the VM using gsutil.`},
        ],
        'answerId': [0],
        'rationale': []
    },
    {
        'id':2405,
        'question':`You are tasked to migrate on prem 100 TB of data into Google Cloud, You have 1 Gbps connection with GCP. You want to use Cloud Storage and follow Google-recommended practices. What should you do?`,
        'answers': [
            {'id':0, 'desc':'Install gsutil on each server containing data. Use streaming transfers to upload the data into Cloud Storage.'},
            {'id':1, 'desc':'Move your data onto a Transfer Appliance. Use Cloud Dataprep to decrypt the data into Cloud Storage.'},
            {'id':2, 'desc':'Install gsutil on each server that contains data. Use resumable transfers to upload the data into Cloud Storage.'},
            {'id':3, 'desc':'Move your data onto a Transfer Appliance. Use a Transfer Appliance Rehydrator to decrypt the data into Cloud Storage.'},
        ],
        'answerId': [2],
        'rationale': [{
            'id':2, 'desc':'https://cloud.google.com/solutions/migration-to-google-cloud-transferring-your-large-datasets',
        }]
    },
    {
        'id':2407,
        'question':`What are two of the actions you can take to troubleshoot a virtual machine instance that won't start up at all?`,
        'options': ['1. Increase the CPU and memory on the instance by changing the machine type.',
        '2. Examine your virtual machine instance\'s serial port output.',
        '3. Connect to your virtual machine instance using SSH.',
        '4. Validate that your disk has a valid file system.'],
        'answers': [
            {'id':0, 'desc':'1 & 2'},
            {'id':1, 'desc':'2 & 3'},
            {'id':2, 'desc':'1 & 4'},
            {'id':3, 'desc':'3 & 4'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':'https://cloud.google.com/compute/docs/troubleshooting/vm-startup'}
        ]
    },
    {
        'id':2408,
        'question':`You are tasked to setup virtual private network between your on-prem servers(network) with Google Cloud Platform and have the routes automatically updated when the network topology changes, What is the minimal number of each type of component you need to implement?`,
        'answers': [
            {'id':0, 'desc':'2 Cloud VPN Gateways and 1 Cloud Router'},
            {'id':1, 'desc':'1 Cloud VPN Gateway, 1 Peer Gateway, and 1 Cloud Router'},
            {'id':2, 'desc':'2 Cloud VPN Gateways per region and 1 Peer Gateway'},
            {'id':3, 'desc':'2 Peer Gateways and 1 Cloud Router'},
        ],
        'answerId': [1],
        'rationale': []
    },
    {
        'id':2409,
        'question':`Company has python based watchdog application runs steady compute (  0.1 vCPU , and 128 MB Memory), Which set of steps should you take? Note : Consider management of infrastructure as well as cost while making suggestions.`,
        'answers': [
            {'id':0, 'desc':'1. Create a managed instance group with f1-micro type machines. 2. Use a startup script to clone the repository, check out the production branch, install the dependencies, and start the Python app. 3. Restart the instances to automatically deploy new production releases'},
            {'id':1, 'desc':'1. Create a GKE cluster with n1-standard-4 type machines. 2. Build a Docker image from the master branch with all of the dependencies, and tag it with "latest". 3. Create a Kubernetes Deployment in the default namespace with the imagePullPolicy set to "Always". Restart the pods to automatically deploy new production releases.'},
            {'id':2, 'desc':'1. Create a Kubernetes Engine cluster with n1-standard-1 type machines. 2. Build a Docker image from the production branch with all of the dependencies, and tag it with the version number. 3. Create a Kubernetes Deployment with the imagePullPolicy set to "IfNotPresent" in the staging namespace, and then promote it to the production namespace after testing.'},
            {'id':3, 'desc':'1. Create a managed instance group with n1-standard-1 type machines. 2. Build a Compute Engine image from the production branch that contains all of the dependencies and automatically starts the Python app. 3. Rebuild the Compute Engine image, and update the instance template to deploy new production releases.'},
        ],
        'answerId': [3],
        'rationale': []
    },
    {
        'id':2410,
        'question':`What option does Cloud SQL offer to help with high availability?`,
        'answers': [
            {'id':0, 'desc':'Failover replicas'},
            {'id':1, 'desc':'Point-in-time recover'},
            {'id':2, 'desc':'The AlwaysOn setting'},
            {'id':3, 'desc':'Snapshots'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':'https://cloud.google.com/sql/docs/mysql/high-availability#failover-overview'}
        ]
    },
    {
        'id':2411,
        'question':`You have web application which has several VM instances running within a VPC. You want to restrict communications between instances to only the paths and ports you authorize, but you don't want to rely on static IP addresses or subnets because the app can autoscale. How should you restrict communications?`,
        'answers': [
            {'id':0, 'desc':'Use Cloud DNS'},
            {'id':1, 'desc':'Use Service account and configure the web application using particular service account. '},
            {'id':2, 'desc':'Use firewall rules based on network tags attached to Compute Engine instance. '},
            {'id':3, 'desc':'Use separate VPCs'},
        ],
        'answerId': [2],
        'rationale': [
            {'id':2, 'desc':'Firewall rules tags are used to restrict communication between VMs.'},]
    },
    {
        'id':2412,
        'question':`Which of the follow products will allow you to host a static website?`,
        'answers': [
            {'id':0, 'desc':'Cloud SDK'},
            {'id':1, 'desc':'Cloud Shell'},
            {'id':2, 'desc':'Cloud Endpoints'},
            {'id':3, 'desc':'Cloud Storage'},
        ],
        'answerId': [3],
        'rationale': []
    },
    {
        'id':2413,
        'question':`An engineer is designing applications that needs to use websockets and HTTP sessions that are not distributed across the web servers. You want to help him ensure his application will run properly on Google Cloud Platform.
        What is your recommendation ?`,
        'answers': [
            {'id':0, 'desc':'Meet with the cloud operations team and the engineer to discuss load balancer options'},
            {'id':1, 'desc':'Review the encryption requirements for websocket connections with the security team'},
            {'id':2, 'desc':'Help the engineer to convert his websocket code to use HTTP streaming'},
            {'id':3, 'desc':'Help the engineer redesign the application to use a distributed user session service that does not rely on websockets and HTTP sessions'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':'HTTP Load Balancer supports Websocket'},]
    },
    {
        'id':2414,
        'question':`Company wants to migrate existing Game Analytics (MySQL) to Google Cloud Platform and wants to follow Google Recommended best practices, What will be your recommendation to create realtime analytics solution for time series data to sale blastups, What will be your choice of database for this use case ?`,
        'answers': [
            {'id':0, 'desc':'Cloud SQL'},
            {'id':1, 'desc':'Cloud BigQuery'},
            {'id':2, 'desc':'Cloud BigTable'},
            {'id':3, 'desc':'Cloud Dataproc'},
        ],
        'answerId': [2],
        'rationale': [
            {'id':2, 'desc':'Cloud Bigtable used for analytics for low latency & for time series data requirements.'},]
    },
    {
        'id':2415,
        'question':`You have an application deployed on Kubernetes Engine using a Deployment named echo-deployment. The deployment is exposed using a Service called echo service. You need to perform an update to the application with minimal downtime to the application. What should you do?`,
        'answers': [
            {'id':0, 'desc':'Use the rolling update functionality of the Instance Group behind the Kubernetes cluster'},
            {'id':1, 'desc':'Update the service yaml file which the new container image. Use kubectl delete service/echo-service and kubectl create ""f <yaml-file>'},
            {'id':2, 'desc':'Use kubectl set image deployment echo-deployment <new-image>'},
            {'id':3, 'desc':'Update the deployment yaml file with the new container image. Use kubectl delete deployment/echo-deployment and kubectl create ""f <yaml-file>'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':'You can use kubectl to update application image: https://cloud.google.com/kubernetes-engine/docs/how-to/updating-apps#updating_an_application'},
        ]
    },
];



// Case Study??
export const setE = [

    {
        'id':2516,
        'question':`You set up an autoscaling managed instance group to serve web traffic for an upcoming launch. After configuring the instance group as a backend service to an HTTP(S) load balancer, you notice that virtual machine (VM) instances are being terminated and re-launched every minute. The instances do not have a public IP address. You have verified that the appropriate web response is coming from each instance using the curl command. You want to ensure that the backend is configured correctly. What should you do?`,
        'answers': [
            {'id':0, 'desc':`Ensure that a firewall rule exists to allow source traffic on HTTP/HTTPS to reach the load balancer`},
            {'id':1, 'desc':`Assign a public IP to each instance, and configure a firewall rule to allow the load balancer to reach the instance public IP.`},
            {'id':2, 'desc':`Ensure that a firewall rule exists to allow load balancer health checks to reach the instances in the instance group.`},
            {'id':3, 'desc':`Create a tag on each instance with the name of the load balancer. Configure a firewall rule with the name of the load balancer as the source and the instance tag as the destination.`},
        ],
        'answerId': [2],
        'rationale': [
            {'id':0, 'desc':``},]
    },
    {
        'id':2517,
        'question':`Your company wants to try out the cloud with low risk. They want to archive approximately 100 TB of their log data to the cloud and test the serverless analytics features available to them there, while also retaining that data as a long-term disaster recovery backup. Which two steps should they take? (choose two)`,
        'answers': [
            {'id':0, 'desc':`Load logs into BigQuery.`},
            {'id':1, 'desc':`Load logs into Cloud SQL.`},
            {'id':2, 'desc':`Import logs into Cloud Logging.`},
            {'id':3, 'desc':`Insert logs into Cloud Bigtable.`},
            {'id':4, 'desc':`Upload log files into Cloud Storage.`},
        ],
        'answerId': [0,4],
        'rationale': [
            {'id':0, 'desc':``},]
    },
    {
        'id':2523,
        'question':`Your company wants to deploy several microservices to help their system handle elastic loads. Each microservice uses a different version of software libraries. You want to enable their developers to keep their development environment in sync with the various production services. Which technology should you choose?`,
        'answers': [
            {'id':0, 'desc':`RPM/DEB`},
            {'id':1, 'desc':`Containers`},
            {'id':2, 'desc':`Chef/Puppet`},
            {'id':3, 'desc':`Virtual machines`},
        ],
        'answerId': [1],
        'rationale': [
            {'id':0, 'desc':``},]
    },
    {
        'id':2524,
        'question':`Your company wants to track whether someone is present in a meeting room reserved for a scheduled meeting. There are 1000 meeting rooms across 5 offices on 3 continents. Each room is equipped with a motion sensor that reports its status every second. You want to support the data ingestion needs of this sensor network. The receiving infrastructure needs to account for the possibility that the devices may have inconsistent connectivity. Which solution should you design?`,
        'answers': [
            {'id':0, 'desc':`Have each device create a persistent connection to a Compute Engine instance and write messages to a custom application.`},
            {'id':1, 'desc':`Have devices poll for connectivity to Cloud SQL and insert the latest messages on a regular interval to a device specific table.`},
            {'id':2, 'desc':`Have devices poll for connectivity to Cloud Pub/Sub and publish the latest messages on a regular interval to a shared topic for all devices.`},
            {'id':3, 'desc':`Have devices create a persistent connection to an App Engine application fronted by Cloud Endpoints, which ingest messages and write them to Cloud Datastore.`},
        ],
        'answerId': [2],
        'rationale': [
            {'id':0, 'desc':``},]
    },
    {
        'id':2525,
        'question':`To reduce costs, the Director of Engineering has required all developers to move their development infrastructure resources from on-premises virtual machines (VMs) to Google Cloud. These resources go through multiple start/stop events during the day and require state to persist. You have been asked to design the process of running a development environment in Google Cloud while providing cost visibility to the finance department. Which two steps should you take? (choose two)`,
        'answers': [
            {'id':0, 'desc':`Use persistent disks to store the state. Start and stop the VM as needed.`},
            {'id':1, 'desc':`Use the --auto-delete flag on all persistent disks before stopping the VM.`},
            {'id':2, 'desc':`Apply VM CPU utilization label and include it in the BigQuery billing export.`},
            {'id':3, 'desc':`Use BigQuery billing export and labels to relate cost to groups.`},
            {'id':4, 'desc':`Store all state in a Local SSD, snapshot the persistent disks, and terminate the VM`},
        ],
        'answerId': [0,3],
        'rationale': [
            {'id':0, 'desc':``},]
    },
    {
        'id':2526,
        'question':` You are designing a large distributed application with 30 microservices. Each of your distributed microservices needs to connect to a database backend. You want to store the credentials securely. Where should you store the credentials?`,
        'answers': [
            {'id':0, 'desc':`In the source code`},
            {'id':1, 'desc':`In an environment variable`},
            {'id':2, 'desc':`In a secret management system`},
            {'id':3, 'desc':`In a config file that has restricted access through ACLs`},
        ],
        'answerId': [2],
        'rationale': [
            {'id':0, 'desc':`A secret management system such as Secret Manager is a secure and convenient storage system for API keys, passwords, certificates, and other sensitive data. Secret Manager provides a central place and single source of truth to manage, access, and audit secrets across Google Cloud.`},]
    },
    {
        'id':2417,
        'question':`Company wants to move existing application data ingest based on Microsoft SQL server to Google Cloud SQL Server, What configuration will you recommend to have server on Failover Clustering ?`,
        'answers': [
            {'id':0, 'desc':'Use Kubernetes to deploy SQL Server to take care high availability '},
            {'id':1, 'desc':'Use Google Cloud SQL Server instance - its always configured for high availability by default. '},
            {'id':2, 'desc':'Use Instance Groups and Template and use Microsoft SQL Server image to launch instances.  '},
            {'id':3, 'desc':'Configure AlwaysOn Availability Groups for all instance in cluster'},
        ],
        'answerId': [3],
        'rationale': []
    },
    {
        'id':2422,
        'question':`Your customer is moving their corporate applications to Google Cloud. The security team wants detailed visibility of all resources in the organization. You use Resource Manager to set yourself up as the Organization Administrator. Which Cloud Identity and Access Management (Cloud IAM) roles should you give to the security team while following Google recommended practices?`,
        'answers': [
            {'id':0, 'desc':'Organization viewer, Project owner'},
            {'id':1, 'desc':'Organization viewer, Project viewer'},
            {'id':2, 'desc':'Organization administrator, Project browser'},
            {'id':3, 'desc':'Project owner, Network administrator'},
        ],
        'answerId': [1],
        'rationale': [{'id':1, 'desc':`-Organization viewer grants the security team permissions to view the organization's display name.
        -Project viewer grants the security team permissions to see the resources within projects.`},]
    },
    {
        'id':2423,
        'question':`The database administration team has asked you to help them improve the performance of their new database server running on Compute Engine. The database is used for importing and normalizing the company’s performance statistics. It is built with MySQL running on Debian Linux. They have an n1-standard-8 virtual machine with 80 GB of SSD zonal persistent disk which they can't restart until the next maintenance event. What should they change to get better performance from this system as soon as possible and in a cost-effective manner?`,
        'answers': [
            {'id':0, 'desc':'Increase the virtual machine’s memory to 64 GB.'},
            {'id':1, 'desc':'Create a new virtual machine running PostgreSQL.'},
            {'id':2, 'desc':'Dynamically resize the SSD persistent disk to 500 GB.'},
            {'id':3, 'desc':'Migrate their performance metrics warehouse to BigQuery.'},
        ],
        'answerId': [2],
        'rationale': [{'id':2, 'desc':`Persistent disk performance is based on the total persistent disk capacity attached to an instance and the number of vCPUs that the instance has. Incrementing the persistent disk capacity will increment its throughput and IOPS, which in turn improve the performance of MySQL.`},]
    }
];

export const setF = [
    {
        'id':2015,
        'question':`How can you minimize the cost of storing security video files that are processed repeatedly for 30 days?`,
        'answers': [
            {'id':0, 'desc':'Standard Storage, then move to Coldline Storage or Archive Storage after 30 days.'},
            {'id':1, 'desc':'Nearline Storage, then move to Coldline Storage after 30 days.'},
            {'id':2, 'desc':'Standard Storage, then move to Nearline Storage after 30 days.'},
            {'id':3, 'desc':'Keep the files in Standard Storage.'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':'Standard Storage for lowest access costs over the 30 days, then Coldline Storage or Archive Storage because it is unlikely to be read after the 30 days.'},
            {'id':1, 'desc':`Using Nearline Storage over the 30 days won't be cost effective because the data is accessed too frequently. There is also a 30 day minimum storage duration.`},
            {'id':2, 'desc':`Moving from Standard Storage to Nearline Storage after the 30 days isn’t as cost effective as Coldline Storage or Archive Storage if the data is not going to be accessed that frequently.`},
            {'id':3, 'desc':`Keeping the data in Standard Storage is the least cost effective option if it is not going to be accessed frequently after 30 days.`},
        ]
    },
    {
        'id':2017,
        'question':`Where should you upload unstructured Data?`,
        'answers': [
            {'id':0, 'desc':'Cloud Storage/Cloud Firestore'},
            {'id':1, 'desc':'Cloud Bigtable'},
            {'id':2, 'desc':'BigQuery'},
            {'id':3, 'desc':'Spanner'},
            {'id':4, 'desc':'Cloud SQL'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':`Unstructured/Files`},
            {'id':1, 'desc':`Requires Semi-Structured NoSQL`},
            {'id':2, 'desc':`Requires Structured SQL`},
            {'id':3, 'desc':`Requires Structured SQL`},
            {'id':4, 'desc':`Requires Structured SQL`}
        ]
    },
    {
        'id':2018,
        'question':`After creating a Deployment named "hello-server", you need to expose it to the internet so that users can access it. You can expose your application by creating a Service, a Kubernetes resource that exposes your application to external traffic.
        To expose your application, run the following`,
        'answers': [
            {'id':0, 'desc':'kubectl expose deployment hello-server --type LoadBalancer --port 80 --target-port 8080'},
            {'id':1, 'desc':'kubectl run deployment hello-server --type LoadBalancer --port 80 --target-port 8080'},
            {'id':2, 'desc':'gcloud expose deployment hello-server --type LoadBalancer --port 80 --target-port 8080'},
            {'id':3, 'desc':'gsutil run deployment hello-server --type LoadBalancer --port 80 --target-port 8080'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':`kubectl expose will load balance traffic across the running instances, and can create a HA proxy for accessing the containers from outside the cluster.
            Passing in the --type LoadBalancer flag creates a Compute Engine load balancer for your container. The --port flag initializes public port 80 to the internet and the --target-port flag routes the traffic to port 8080 of the application.`},
            {'id':1, 'desc': `kubectl run will start running 1 or more instances of a container image on your cluster.`},
            {'id':2, 'desc':'gcloud can create a GKE cluster, but this command will not expose it'},
            {'id':3, 'desc':'gsutil is not an applicable command here'},
        ]
    },
    {
        'id':2019,
        'question':`What is the best practice for Indexes?`,
        'answers': [
            {'id':0, 'desc':'If a property will never be needed for a query, exclude the property from indexes.'},
            {'id':1, 'desc':'Avoid having too many composite indexes.'},
            {'id':2, 'desc':'Do not index properties with monotonically increasing values (such as a NOW() timestamp)'},
            {'id':3, 'desc':'All of the above'},
        ],
        'answerId': [3],
        'rationale': [
            {'id':3, 'desc':``}
        ]
    },
    {
        'id':2021,
        'question':`During a high traffic portion of the day, one of your relational databases crashes, but the replica is never promoted to a master. You want to avoid this in the future.
        What should you do?`,
        'answers': [
            {'id':0, 'desc':'Use a different database'},
            {'id':1, 'desc':'Choose larger instances for your database'},
            {'id':2, 'desc':'Create snapshots of your database more regularly'},
            {'id':3, 'desc':'Implement routinely scheduled failovers of your databases'},
        ],
        'answerId': [3],
        'rationale': [
            {'id':3, 'desc':`Routine failovers can help ensure that the failover process is working correctly and that the system is resilient to crashes`}
        ]
    },
    {
        'id':2022,
        'question':`One of the developers on your team deployed their application in Google Container Engine with the Dockerfile below. They report that their application deployments are taking too long. You want to optimize this Dockerfile for faster deployment times without adversely affecting the app's functionality.
        Which two actions should you take?`,
        'img':code72e15b4b,
        'answers': [
            {'id':0, 'desc':'Remove Python after running pip'},
            {'id':1, 'desc':'Remove dependencies from requirements.txt'},
            {'id':2, 'desc':'Use a slimmed-down base image like Alpine Linux'},
            {'id':3, 'desc':'Use larger machine types for your Google Container Engine node pools'},
            {'id':4, 'desc':'Copy the source after the package dependencies (Python and pip) are installed'},
        ],
        'answerId': [2,4],
        'rationale': [
            {'id':2, 'desc':`The speed of deployment can be changed by limiting the size of the uploaded app, limiting the complexity of the build necessary in the Dockerfile, if present, and by ensuring a fast and reliable internet connection.
            Note: Alpine Linux is built around musl libc and busybox. This makes it smaller and more resource efficient than traditional GNU/Linux distributions. A container requires no more than 8 MB and a minimal installation to disk requires around 130 MB of storage. Not only do you get a fully-fledged Linux environment but a large selection of packages from the repository.`}
        ]
    },
    {
        'id':2023,
        'question':`Your company's user-feedback portal comprises a standard LAMP stack replicated across two zones. It is deployed in the us-central1 region and uses autoscaled managed instance groups on all layers, except the database. Currently, only a small group of select customers have access to the portal. The portal meets a
        99,99% availability SLA under these conditions. However next quarter, your company will be making the portal available to all users, including unauthenticated users. You need to develop a resiliency testing strategy to ensure the system maintains the SLA once they introduce additional user load.
        What should you do?`,
        'answers': [
            {'id':0, 'desc':'Capture existing users input, and replay captured user load until autoscale is triggered on all layers. At the same time, terminate all resources in one of the zones'},
            {'id':1, 'desc':'Create synthetic random user input, replay synthetic load until autoscale logic is triggered on at least one layer, and introduce "chaos" to the system by terminating random resources on both zones'},
            {'id':2, 'desc':'Expose the new system to a larger group of users, and increase group size each day until autoscale logic is triggered on all layers. At the same time, terminate random resources on both zones'},
            {'id':3, 'desc':'Capture existing users input, and replay captured user load until resource utilization crosses 80%. Also, derive estimated number of users based on existing user\'s usage of the app, and deploy enough resources to handle 200% of expected load'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':`Create synthetic random user input, replay synthetic load until autoscale logic is triggered on at least one layer, and introduce chaos to the system by terminating random resources on both zones
            >> This option takes care of the requirement of testing. Is a pattern used by org like Netflix chaos monkey).`}
        ]
    },
    {
        'id':2024,
        'question':`What type of storage is limited to a capacity of 10,230 GB?`,
        'answers': [
            {'id':0, 'desc':'Cloud Datastore'},
            {'id':1, 'desc':'Bigtable'},
            {'id':2, 'desc':'Cloud Storage'},
            {'id':3, 'desc':'Cloud SQL'},
            {'id':4, 'desc':'Cloud Spanner'},
            {'id':5, 'desc':'Cloud BigQuery'},
        ],
        'answerId': [3],
        'rationale': [
            {'id':0, 'desc':'Terabytes+ Limit with 1MB/entity Unit size'},
            {'id':1, 'desc':'Petabytes+ Limit with ~10MB/cell ~100 MB/row Unit size'},
            {'id':2, 'desc':'Petabytes+ Limit with 5TB/object Unit size'},
            {'id':3, 'desc':'10,230 Limit with Unit size determined by DB engine'},
            {'id':4, 'desc':'Petabytes Limit with 10,240 MiB/row Unit size'},
            {'id':5, 'desc':'Petabytes+ Limit with 10 MB/row Unit size'},
        ]
    },
    {
        'id':2025,
        'question':`_____ is a fast, easy-to-use, low-cost and fully managed service that lets you run the Apache Spark and Apache Hadoop ecosystem on Google
        Cloud Platform. _______ provisions big or small clusters rapidly, supports many popular job types, and is integrated with other Google Cloud Platform services, such as Google Cloud Storage and Stackdriver Logging, thus helping you reduce TCO`,
        'answers': [
            {'id':0, 'desc':'Google Cloud Dataflow'},
            {'id':1, 'desc':'Google Cloud Dataproc'},
            {'id':2, 'desc':'Google Compute Engine'},
            {'id':3, 'desc':'Google Kubernetes Engine'},
        ],
        'answerId': [1,3],
        'rationale': [
            {'id':1, 'desc':``}
        ]
    },
    {
        'id':2026,
        'question':`What are some of Google Cloud Platform's pricing innovations? Select all that Qualify`,
        'answers': [
            {'id':0, 'desc':'Robust Infrasturcture'},
            {'id':1, 'desc':'Sustained-use discounts'},
            {'id':2, 'desc':'Compute Engine custom machine types'},
            {'id':3, 'desc':'Open-source technologies'},
            {'id':4, 'desc':'Sub-hour billing'},
        ],
        'answerId': [1,2,4],
        'rationale': [
            {'id':1, 'desc':`Sub-hour billing, Sustained-use discounts and Compute Engine custom machine types are pricing innovations`}
        ]
    },
    {
        'id':2027,
        'question':`Google's Data Centers were the first to achieve ISO 14001 certification`,
        'answers': [
            {'id':0, 'desc':'true'},
            {'id':1, 'desc':'false'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':`Google works to make data centers runas efficiently as possible. Google's data centers were the first to achieve iso 14001 certification. Running data workloads on GCP can help companies achieve their environmental goals.`}
        ]
    },
    {
        'id':2028,
        'question':`Why might a GCP customer use resources in several regions around the world?`,
        'answers': [
            {'id':0, 'desc':'To bring their applications closer to users around the world, and for improved fault tolerance'},
            {'id':1, 'desc':'To improve security'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':``}
        ]
    },
    {
        'id':2029,
        'question':`Why might a GCP customer use resources in several zones within a region?`,
        'answers': [
            {'id':0, 'desc':'For improved fault tolerance'},
            {'id':1, 'desc':'For better performance'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':`As part of building a fault-tolerant application, you can spread your resources across multiple zones in a region.`}
        ]
    },
];

export const setG = [
    {
        'id':2420,
        'question':`Marketing company have digital assets (Videos, Images, flyer, audios etc) hosted in Cloud Storage. These assets are used rarely by different business units, You are tasked to design solution to systematically distribute the cost to different accounts/projects based on their usage. Company is ready to pay minimum cost to store the data in cloud storage. What will be your solution to distribute cost?`,
        'answers': [
            {'id':0, 'desc':'Storage data in individual buckets in respective project, configure lifecycle polity to move data between different projects based on need.'},
            {'id':1, 'desc':'Store data in Cloud Storage with Coldline storage bucket, This will reduce storage cost for huge data.'},
            {'id':2, 'desc':'Create buckets in different projects and copy the content in all buckets and depends on data access choose Nearline or Coldline as storage class to save cost'},
            {'id':3, 'desc':'Store data in common bucket and provide access to all accounts/projects, Set Storage class to Nearline or Coldline based on access pattern, Configure Requester pays (access fees) for all bucket.'},
        ],
        'answerId': [3],
        'rationale': [
            {'id':3, 'desc':'Requester pays enables fees(access fees) to be paid by requester and not by owner.'}
        ]
    },
    {
        'id':2421,
        'question':`The development team has provided you with a Kubernetes Deployment file. You have no infrastructure yet and need to deploy the application. What should you do?`,
        'answers': [
            {'id':0, 'desc':'Use gcloud to create a Kubernetes cluster. Use Deployment Manager to create the deployment.'},
            {'id':1, 'desc':'Use gcloud to create a Kubernetes cluster. Use kubectl to create the deployment.'},
            {'id':2, 'desc':'Use kubectl to create a Kubernetes cluster. Use kubectl to create the deployment.'},
            {'id':3, 'desc':'Use kubectl to create a Kubernetes cluster. Use Deployment Manager to create the deployment.'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':''}
        ]
    },
    {
        'id':2422,
        'question':`What is the effective policy for a resource at a node?`,
        'answers': [
            {'id':0, 'desc':'The effective policy is the union of the policy set at the node and policies inherited from its ancestors'},
            {'id':1, 'desc':'The effective policy is determined only by the policy set at the node'},
            {'id':2, 'desc':'The effective policy is the policy set at the node and restricted by the policies of its ancestors'},
            {'id':3, 'desc':'The effective policy is the intersection of the policy set at the node and policies inherited from its ancestors'},
        ],
        'answerId': [0],
        'rationale': [
            {'id':0, 'desc':`The effective policy for a resource is the union of the policy set on the resource and the policy inherited from its ancestors. This inheritance is transitive. In other words, resources inherit policies from the project, which inherit policies from the organization`}
        ]
    },
    {
        'id':2423,// eslint-disable-next-line
        'question':`You have been asked to select the storage system for the click-data of your company\'s large portfolio of websites. This data is streamed in from a custom website analytics package at a typical rate of 6,000 clicks per minute. With bursts of up to 8,500 clicks per second. It must have been stored for future analysis by your data science and user experience teams. Which storage infrastructure should you choose?`,
        'answers': [
            {'id':0, 'desc':'Google Cloud SQL'},
            {'id':1, 'desc':'Google Cloud Bigtable'},
            {'id':2, 'desc':'Google Cloud Storage'},
            {'id':3, 'desc':'Google Cloud Datastore'},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':`Google cloud Bigtable is most suitable as it is for analysis and streamed data`}
        ]
    },
    {
        'id':2424,
        'question':`You are creating a solution to remove backup files older than 90 days from your backup Cloud Storage bucket. You want to optimize ongoing Cloud Storage spend.
        What should you do?`,
        'answers': [
            {'id':0, 'desc':'Write a lifecycle management rule in XML and push it to the bucket with gsutil'},
            {'id':1, 'desc':'Write a lifecycle management rule in JSON and push it to the bucket with gsutil'},
            {'id':2, 'desc':'Schedule a cron script using gsutil ls ג€"lr gs://backups/** to find and remove items older than 90 days '},
            {'id':3, 'desc':'Schedule a cron script using gsutil ls ג€"l gs://backups/** to find and remove items older than 90 days and schedule it with cron '},
        ],
        'answerId': [1],
        'rationale': [
            {'id':1, 'desc':`Cloud Storage has lifecycle management rules and could be applied with gsutil and gcloud storage buckets. It is common to use JSON for transferring data.`}
        ]
    },
    {
        'id':2425,
        'question':`Which GCP offering has the following characteristics?
        Good for: Low-latency read/write access; High-throughput analytics; Native time series support. Common workloads: IoT, finance, adtech; Personalization, recommendations; Monitoring; Geospatial datasets;Graphs`,
        'answers': [
            {'id':0, 'desc':'Google Cloud SQL'},
            {'id':1, 'desc':'Google Cloud Bigtable'},
            {'id':2, 'desc':'Google Cloud Storage'},
            {'id':3, 'desc':'Google Cloud Datastore'},
        ],
        'answerId': [1],
        'rationale': [{'id':1, 'desc':'Google Cloud Bigtable is a scalable, fully-managed NoSQL wide-column database that is suitable for both real-time access and analytics workloads. Reference: https://cloud.google.com/storage-options/ '}]
    },
    {
        'id':2426,
        'question':`Which GCP offering has the following characteristics?
        Good for: Images, pictures, and videos; Objects and blobs;Unstructured data`,
        'answers': [
            {'id':0, 'desc':'Google Cloud SQL'},
            {'id':1, 'desc':'Google Cloud Bigtable'},
            {'id':2, 'desc':'Google Cloud Storage'},
            {'id':3, 'desc':'Google Cloud Datastore'},
        ],
        'answerId': [2],
        'rationale': [{'id':2, 'desc':'Google Cloud Storage is a scalable, fully-managed, highly reliable, and cost-efficient object / blob store. Reference: https://cloud.google.com/storage-options/ '}]
    },
    {
        'id':2427,
        'question':`Which GCP offering has the following characteristics?
        Good for: Semi-structured application data; Hierarchical data; Durable key-value data. Common workloads: User profiles, Product catalogs, Game state`,
        'answers': [
            {'id':0, 'desc':'Google Cloud SQL'},
            {'id':1, 'desc':'Google Cloud Bigtable'},
            {'id':2, 'desc':'Google Cloud Storage'},
            {'id':3, 'desc':'Google Cloud Datastore'},
        ],
        'answerId': [3],
        'rationale': [{'id':3, 'desc':'Google Cloud Datastore is a scalable, fully-managed NoSQL document database for your web and mobile applications. Reference: https://cloud.google.com/storage-options/ '}]
    },
    {
        'id':2428,
        'question':`Your company is forecasting a sharp increase in the number and size of Apache Spark and Hadoop jobs being run on your local datacenter. You want to utilize the cloud to help you scale this upcoming demand with the least amount of operations work and code change.
        Which product should you use?`,
        'answers': [
            {'id':0, 'desc':'Google Cloud Dataflow'},
            {'id':1, 'desc':'Google Cloud Dataproc'},
            {'id':2, 'desc':'Google Compute Engine'},
            {'id':3, 'desc':'Google Kubernetes Engine'},
        ],
        'answerId': [1],
        'rationale': [{'id':1, 'desc':`Google Cloud Dataproc is a fast, easy-to-use, low-cost and fully managed service that lets you run the Apache Spark and Apache Hadoop ecosystem on Google
        Cloud Platform. Cloud Dataproc provisions big or small clusters rapidly, supports many popular job types, and is integrated with other Google Cloud Platform services, such as Google Cloud Storage and Stackdriver Logging, thus helping you reduce TCO.
        Reference:
        https://cloud.google.com/dataproc/docs/resources/faq`}]
    },
    {
        'id':2429,
        'question':`A small number of API requests to your microservices-based application take a very long time. You know that each request to the API can traverse many services.
        You want to know which service takes the longest in those cases.
        What should you do?`,
        'answers': [
            {'id':0, 'desc':'Set timeouts on your application so that you can fail requests faster'},
            {'id':1, 'desc':'Send custom metrics for each of your requests to Stackdriver Monitoring'},
            {'id':2, 'desc':'Use Stackdriver Monitoring to look for insights that show when your API latencies are high'},
            {'id':3, 'desc':'Instrument your application with Stackdriver Trace in order to break down the request latencies at each microservice'},
        ],
        'answerId': [3],
        'rationale': [{'id':3, 'desc':`https://cloud.google.com/trace/docs/quickstart#find_a_trace `}]
    },
];