// Mountkirk Games
export const mountKirk = [
  {
    'id':24000,
    'question':`Company want to migrate game analytics transformation engine (ETL) for both stream and file upload by mobile to google recommended transformation engine, What will be best choice looking at Mountkirk games case studies?`,
    'answers': [
        {'id':0, 'desc':'Cloud Dataflow'},
        {'id':1, 'desc':'Cloud Datalab'},
        {'id':2, 'desc':'Cloud Dataproc'},
        {'id':3, 'desc':'Cloud composer'},
    ],
    'answerId': [0],
    'rationale': [
        {'id':0, 'desc':'The implementation can be treated as green field (rip and replace) and Cloud Dataflow (Apache Beam) is best suitable (google recommended)'}
    ]
},
  {
      'id':24010,
      'question':`For this question, refer to the Mountkirk Games case study.
      Your company is an industry-leading ISTQB certified software testing firm, and Mountkirk Games has recently partnered with your company for designing their new testing strategy. Given the experience with scaling issues in the existing solution, Mountkirk Games is concerned about the ability of the new backend to scale based on traffic and has asked for your opinion on how to design their new test strategy to ensure scaling issues do not repeat. What should you suggest?`,
      'answers': [
          {'id':0, 'desc':`Modify the test strategy to scale tests well beyond the current approach.`},
          {'id':1, 'desc':`Update the test strategy to replace unit tests with end to end integration tests.`},
          {'id':2, 'desc':`Modify the test strategy to run tests directly in production after each new release.`},
          {'id':3, 'desc':`Update the test strategy to test all infrastructure components in Google Cloud Platform.`},
      ],
      'answerId': [0],
      'rationale': [
          {'id':0, 'desc':``},]
  },
  {
      'id':24020,
      'question':`For this question, refer to the Mountkirk Games case study.
      Mountkirk Games anticipates its new game to be hugely popular and expects this to generate vast quantities of time series data. Mountkirk Games is keen on selecting a managed storage service for this time-series data. What GCP service would you recommend?`,
      'answers': [
          {'id':0, 'desc':`Cloud Bigtable.`},
          {'id':1, 'desc':`Cloud Spanner.`},
          {'id':2, 'desc':`Cloud Firestore.`},
          {'id':3, 'desc':`Cloud Memorystore.`},
      ],
      'answerId': [0],
      'rationale': [
          {'id':0, 'desc':``},]
  },
  {
      'id':24030,
      'question':`For this question, refer to the Mountkirk Games case study.
      Mountkirk Games has redesigned parts of its game backend into multiple microservices that operate as HTTP (REST) APIs. Taking into consideration the technical requirements for the game backend platform as well as the business requirements, how should you design the game backend on Google Cloud platform?`,
      'answers': [
          {'id':0, 'desc':`Use a Layer 4 (TCP) Load Balancer and Google Compute Engine VMs in a Managed Instances Group (MIG) with instances in multiple zones in multiple regions.`},
          {'id':1, 'desc':`Use a Layer 4 (TCP) Load Balancer and Google Compute Engine VMs in a Managed Instances Group (MIG) with instances restricted to a single zone in multiple regions.`},
          {'id':2, 'desc':`Use a Layer 7 (HTTPS) Load Balancer and Google Compute Engine VMs in a Managed Instances Group (MIG) with instances in multiple zones in multiple regions.`},
          {'id':3, 'desc':`Use a Layer 7 (HTTPS) Load Balancer and Google Compute Engine VMs in a Managed Instances Group (MIG) with instances restricted to a single zone in multiple regions.`},
      ],
      'answerId': [2],
      'rationale': [
          {'id':0, 'desc':``},]
  },
  {
      'id':24040,
      'question':`For this question, refer to the Mountkirk Games case study.
      Taking into consideration the technical requirements for the game backend platform as well as the game analytics platform, where should you store data in Google Cloud platform?`,
      'answers': [
          {'id':0, 'desc':`1. For time-series data, use Cloud SQL. 2. For historical data queries, use Cloud Bigtable.`},
          {'id':1, 'desc':`1. For time-series data, use Cloud SQL. 2. For historical data queries, use Cloud Spanner.`},
          {'id':2, 'desc':`1. For time-series data, use Cloud BigTable. 2. For historical data queries, use BigQuery.`},
          {'id':3, 'desc':`1. For time-series data, use Cloud BigTable. 2. For historical data queries, use Cloud BigQuery. 3. For transactional data, use Cloud Spanner.`},
      ],
      'answerId': [3],
      'rationale': [
          {'id':0, 'desc':``},]
  },
  {
      'id':24050,
      'question':`For this question, refer to the Mountkirk Games case study.
      Your company is an industry-leading ISTQB certified software testing firm, and Mountkirk Games has recently partnered with your company for designing their new testing strategy. Mountkirk Games is concerned at the potential disruption caused by solar storms to its business. A solar storm last month resulted in downgraded mobile network coverage and slow upload speeds for a vast majority of mobile users in the Mediterranean. As a result, their analytics platform struggled to cope with the late arrival of data from these mobile devices. Mountkirk Games has asked you for your suggestions on avoiding such issues in future. What should you recommend?`,
      'answers': [
          {'id':0, 'desc':`Update the test strategy to include fault injection software and introduce latency instead of faults.`},
          {'id':1, 'desc':`Update the test strategy to test from multiple mobile phone emulators from all GCP regions.`},
          {'id':2, 'desc':`Update the test strategy to introduce random amounts of delay before processing the uploaded analytics files.`},
          {'id':3, 'desc':`Update the test strategy to gather latency information from 1% of users and use this to simulate latency on production-like volume.`},
      ],
      'answerId': [2],
      'rationale': [
          {'id':0, 'desc':``},]
  },
  {
      'id':24060,
      'question':`For this question, refer to the Mountkirk Games case study.
      Your company is an industry-leading ISTQB certified software testing firm, and Mountkirk Games has recently partnered with your company for designing their new testing strategy. Mountkirk Games has recently migrated their backend to GCP and uses continuous deployment to automate releases. Few of their releases have recently caused a loss of functionality within the application, a few other releases have had unintended performance issues. You have been asked to come up with a testing strategy that lets you properly test all new releases while also giving you the ability to test particular new release to scaled-up production-like traffic to detect performance issues. Mountkirk games want their test environments to scale cost-effectively. How should you design the test environments?`,
      'answers': [
          {'id':0, 'desc':`Design the test environments to scale based on simulated production traffic.`},
          {'id':1, 'desc':`Make use of the existing on-premises infrastructure to scale based on simulated production traffic.`},
          {'id':2, 'desc':`Stress tests every single GCP service used by the application individually.`},
          {'id':3, 'desc':`Create multiple static test environments to handle different levels of traffic, e.g. small, medium, big.`},
      ],
      'answerId': [0],
      'rationale': [
          {'id':0, 'desc':``},]
  },
  {
      'id':24070,
      'question':`Your organization has a 3-tier web application deployed in the same Google Cloud Virtual Private Cloud (VPC). Each tier (web, API, and database) scales independently of the others. Network traffic should flow through the web to the API tier, and then on to the database tier. Traffic should not flow between the web and the database tier. How should you configure the network with minimal steps?`,
      'answers': [
          {'id':0, 'desc':`Add each tier to a different subnetwork.`},
          {'id':1, 'desc':`Set up software-based firewalls on individual VMs.`},
          {'id':2, 'desc':`Add tags to each tier and set up routes to allow the desired traffic flow.`},
          {'id':3, 'desc':`Add tags to each tier and set up firewall rules to allow the desired traffic flow.`},
      ],
      'answerId': [3],
      'rationale': [
          {'id':0, 'desc':``},]
  },
  {
      'id':24080,
      'question':`Mountkirk Games wants to set up a real-time analytics platform for their new game. The new platform must meet their technical requirements. Which combination of Google technologies will meet all of their requirements?`,
      'answers': [
          {'id':0, 'desc':`Kubernetes Engine, Cloud Pub/Sub, and Cloud SQL`},
          {'id':1, 'desc':`Cloud Dataflow, Cloud Storage, Cloud Pub/Sub, and BigQuery`},
          {'id':2, 'desc':`Cloud SQL, Cloud Storage, Cloud Pub/Sub, and Cloud Dataflow`},
          {'id':3, 'desc':`Cloud Dataproc, Cloud Pub/Sub, Cloud SQL, and Cloud Dataflow`},
          {'id':4, 'desc':`Cloud Pub/Sub, Compute Engine, Cloud Storage, and Cloud Dataproc`},
      ],
      'answerId': [1],
      'rationale': [
          {'id':0, 'desc':``},]
  },
  {
      'id':24090,
      'question':`Mountkirk wants to use jenkins pipelines to build and deploy code. They have multiple developer for game backend and native applications. Release management want to make sure that release to production only triggered after verification, How they can achieve this?`,
      'answers': [
          {'id':0, 'desc':'Use Spinnaker branch'},
          {'id':1, 'desc':'Use Jenkins branc'},
          {'id':2, 'desc':'Use Spinnaker tags instead of Jenkins'},
          {'id':3, 'desc':'Use Jenkins discover tags'},
      ],
      'answerId': [3],
      'rationale': [
          {'id':3, 'desc':'https://cloud.google.com/solutions/jenkins-on-kubernetes-engine Spinnaker tags can be used as condition before release it in production.  More information can be found in Jenkins website.'},
      ]
  },
  {
      'id':24100,
      'question':`Company wants to simulate analytics processing for games being played over slow (or intermittent) (phone) internet connection while in remote area. How they can achieve it in test environment?`,
      'answers': [
          {'id':0, 'desc':'Migrate gaming analytics to Cloud Dataproc. '},
          {'id':1, 'desc':'Deploy gaming backed in Cloud Function. '},
          {'id':2, 'desc':'Create random delay in collecting and processing data by analytics platform. '},
          {'id':3, 'desc':'Deploy game in Computer Mobile applications simulator( Changes in gaming backend not required )'},
      ],
      'answerId': [2],
      'rationale': [{'id':2, 'desc':`Creating random delay in analytics platform will help MountKirk to test slow data coming from games. The existing game testing application can be used to do this without making any changes to game.`}]
  },
  {
      'id':24110,
      'question':`Mountkirk Games has deployed their new backend on Google Cloud Platform (GCP). You want to create a thorough testing process for new versions of the backend before they are released to the public. You want the testing environment to scale in an economical way. How should you design the process?`,
      'answers': [
          {'id':0, 'desc':'Create a scalable environment in Google Cloud for simulating production load.'},
          {'id':1, 'desc':'Use the existing infrastructure to test the Google Cloud-based backend at scale.'},
          {'id':2, 'desc':'Build stress tests into each component of your application and use resources from the already deployed production backend to simulate load.'},
          {'id':3, 'desc':'Create a set of static environments in Google Cloud to test different levels of load—for example, high, medium, and low.'},
      ],
      'answerId': [0],
      'rationale': [{'id':0, 'desc':`Simulating production load in Google Cloud can scale in an economical way.`},]
  },
  {
      'id':24120,
      'question':`Mountkirk Games wants to set up a continuous delivery pipeline. Their architecture includes many small services that they want to be able to update and roll back quickly. Mountkirk Games has the following requirements: (1) Services are deployed redundantly across multiple regions in the US and Europe, (2) Only frontend services are exposed on the public internet, (3) They can reserve a single frontend IP for their fleet of services, and (4) Deployment artifacts are immutable. Which set of products should they use?`,
      'answers': [
          {'id':0, 'desc':'Cloud Storage, Cloud Dataflow, Compute Engine'},
          {'id':1, 'desc':'Cloud Storage, App Engine, Cloud Load Balancing'},
          {'id':2, 'desc':'Container Registry, Google Kubernetes Engine, Cloud Load Balancing'},
          {'id':3, 'desc':'Cloud Functions, Cloud Pub/Sub, Cloud Deployment Manager'},
      ],
      'answerId': [2],
      'rationale': [{'id':2, 'desc':`-Google Kubernetes Engine is ideal for deploying small services that can be updated and rolled back quickly. It is a best practice to manage services using immutable containers. -Cloud Load Balancing supports globally distributed services across multiple regions. It provides a single global IP address that can be used in DNS records. Using URL Maps, the requests can be routed to only the services that Mountkirk wants to expose. -Container Registry is a single place for a team to manage Docker images for the services.`},]
  },
  {
      'id':24130,
      'question': `Mountkirk Games has deployed their new backend on Google Cloud Platform (GCP). You want to create a thorough testing process for new versions of the backend before they are released to the public. You want the testing environment to scale in an economical way. How should you design the process?`,
      'answers': [
          {'id':0, 'desc':'Create a scalable environment in GCP for simulating production load.'},
          {'id':1, 'desc':'CloUse the existing infrastructure to test the GCP-based backend at scale.'},
          {'id':2, 'desc':'Build stress tests into each component of your application using resources internal to GCP to simulate load.'},
          {'id':3, 'desc':'Create a set of static environments in GCP to test different levels of load — for example, high, medium, and low.'},
      ],
      'answerId': [0],
      'rationale': [{'id':0, 'desc':`Explanation:
      From scenario: Requirements for Game Backend Platform
      
      Dynamically scale up or down based on game activity
      Connect to a managed NoSQL database service
      Run customize Linux distro`},]
  },
  {
      'id':24140,
      'question': `Mountkirk Games has deployed their new backend on Google Cloud Platform (GCP). You want to create a thorough testing process for new versions of the backend before they are released to the public. You want the testing environment to scale in an economical way. How should you design the process?`,
      'answers': [
          {'id':0, 'desc':'Tests should scale well beyond the prior approaches'},
          {'id':1, 'desc':'Unit tests are no longer required, only end-to-end tests.'},
          {'id':2, 'desc':'Tests should be applied after the release is in the production environment.'},
          {'id':3, 'desc':'Tests should include directly testing the Google Cloud Platform (GCP) infrastructure.'},
      ],
      'answerId': [0],
      'rationale': [{'id':0, 'desc':`Explanation:
      From Scenario:
      
      A few of their games were more popular than expected, and they had problems scaling their application servers, MySQL databases, and analytics tools.
      
      Requirements for Game Analytics Platform include: Dynamically scale up or down based on game activity`},]
  },
  {
      'id':24150,
      'question': ``,
      'answers': [
          {'id':0, 'desc':'Create a token and pass it in as an environment variable to func_display. When invoking func_query, include the token in the request Pass the same token to func _query and reject the invocation if the tokens are different.'},
          {'id':1, 'desc':'Make func_query \'Require authentication.\' Create a unique service account and associate it to func_display. Grant the service account invoker role for func_query. Create an id token in func_display and include the token to the request when invoking func_query.'},
          {'id':2, 'desc':'Make func _query \'Require authentication\' and only accept internal traffic. Create those two functions in the same VPC. Create an ingress firewall rule for func_query to only allow traffic from func_display.'},
          {'id':3, 'desc':'Create those two functions in the same project and VPC. Make func_query only accept internal traffic. Create an ingress firewall for func_query to only allow traffic from func_display. Also, make sure both functions use the same service account.'},
      ],
      'answerId': [1],
      'rationale': [{'id':1, 'desc':`Explanation:
      https://cloud.google.com/functions/docs/securing/authenticating#authenticating_function_to_function_calls`},]
  },
  {
      'id':24160,
      'question':`Mountkirk Games' gaming servers are not automatically scaling properly. Last month, they rolled out a new feature, which suddenly became very popular. A record number of users are trying to use the service, but many of them are getting 503 errors and very slow response times. What should they investigate first?`,
      'answers': [
          {'id':0, 'desc':'Verify that the database is online.'},
          {'id':1, 'desc':'Verify that the project quota hasn\'t been exceeded.'},
          {'id':2, 'desc':'Verify that the new feature code did not introduce any performance bugs.'},
          {'id':3, 'desc':'Verify that the load-testing team is not running their tool against production.'},
      ],
      'answerId': [1],
      'rationale': [{'id':1, 'desc':`503 is service unavailable error. If the database was online everyone would get the 503 error.

      https://cloud.google.com/docs/quota#capping_usage`}]
    },
    {
        'id':24170,
        'question':`Mountkirk Games needs to create a repeatable and configurable mechanism for deploying isolated application environments. Developers and testers can access each other's environments and resources, but they cannot access staging or production resources. The staging environment needs access to some services from production.

        What should you do to isolate development environments from staging and production?`,
        'answers': [
            {'id':0, 'desc':'Create a project for development and test and another for staging and production.'},
            {'id':1, 'desc':'Create a network for development and test and another for staging and production.'},
            {'id':2, 'desc':'Create one subnetwork for development and another for staging and production.'},
            {'id':3, 'desc':'Create one project for development, a second for staging and a third for production.'},
        ],
        'answerId': [1],
        'rationale': [{'id':3, 'desc':``}]
      },
];

export const terram = [
  {
    'id':3001,
    'question':`Consider the TerramEarth case study.
    You work for a consulting firm that specializes in providing next-generation digital services and has recently been contracted by TerramEarth to design and develop APIs that would enable TerramEarth to decrease unplanned downtime to less than one week. Given the short period for the project, TerramEarth wants you to focus on delivering APIs that meet their business requirements rather than spend time developing a custom framework that fits the needs of all APIs and their edge case scenarios. What should you do?`,
    'answers': [
        {'id':0, 'desc':`Expose APIs on Google App Engine through Google Cloud Endpoints for dealers and partners.`},
        {'id':1, 'desc':`Expose APIs on Google App Engine to the public.`},
        {'id':2, 'desc':`Expose Open API Specification compliant APIs on Google App Engine to the public.`},
        {'id':3, 'desc':`Expose APIs on Google Kubernetes Engine to the public.`},
        {'id':4, 'desc':`Expose Open API Specification compliant APIs on Google Kubernetes Engine to dealers and partners.`},
    ],
    'answerId': [0],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3002,
    'question':`Consider the TerramEarth case study.
    You work for a consulting firm that specializes in providing next-generation digital services and has recently been contracted by TerramEarth to help them enhance their APIs. One of their APIs used for retrieving vehicle is being used successfully by analysts to predict unplanned downtime and preemptively stock replacement parts. TerramEarth has asked you to enable delegated authorization for 3rd parties so that the dealer network can use this data to better position new products and services. What should you do?
    
    `,
    'answers': [
        {'id':0, 'desc':`Use OAuth 2.0 to delegate authorization.`},
        {'id':1, 'desc':`Use SAML 2.0 to delegate authorization.`},
        {'id':2, 'desc':`Open up the API to IP ranges of the dealer network.`},
        {'id':3, 'desc':`Enable each dealer to share their credentials with their trusted partner.`},
    ],
    'answerId': [0],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3003,
    'question':`Consider the TerramEarth case study.
    TerramEarth would like to reduce unplanned downtime for all its vehicles and preemptively stock replacement parts. To do this, TerramEarth has partnered with another firm to loT enable all vehicles in the field but is concerned that its existing data ingestion solution is not capable of handling the massive increase in ingested data. TerramEarth has asked you to design the data ingestion layer to support this requirement. What should you do?`,
    'answers': [
        {'id':0, 'desc':`Ingest data to Google Cloud Storage directly.`},
        {'id':1, 'desc':`Ingest data through Google Cloud Pub/Sub.`},
        {'id':2, 'desc':`Ingest data to Google BigQuery through streaming inserts.`},
        {'id':3, 'desc':`Continue ingesting data via existing FTP solution.`},
    ],
    'answerId': [1],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3004,
    'question':`TerramEarth would like to reduce unplanned downtime for all its vehicles and preemptively stock replacement parts. To do this, TerramEarth has partnered with another firm to loT enable all vehicles in the field. TerramEarth is concerned that its existing data ingestion solution may not satisfy all use cases. Early analysis has shown the FTP uploads are highly unreliable in areas with poor network connectivity and this frequently causes the FTP upload to restart from the beginning. On occasions, this has resulted in analysts querying old data and failing to predict unplanned downtimes accurately. How should you design the data ingestion layer to make it more reliable while ensuring data is made available to analysts as quickly as possible?`,
    'answers': [
        {'id':0, 'desc':`Replace the existing FTP server with a cluster of FTP servers on a single GKE cluster. 2. After receiving the files, push them to Multi-Regional Cloud Storage bucket. 3. Modify the ETL process to pick up files from this bucket.`},
        {'id':1, 'desc':`Replace the existing FTP server with multiple FTP servers running in GKE clusters in multiple regions. 2. After receiving the files, push them a Multi-Regional Cloud Storage bucket in the same region. 3. Modify the ETL process to pick up files from this bucket.`},
        {'id':2, 'desc':`Use Google HTTP(s) APIs to upload files to multiple Multi-Regional Cloud Storage Buckets. 2. Modify the ETL process to pick up files from these buckets.`},
        {'id':3, 'desc':`Use Google HTTP(s) APIs to upload files to multiple Regional Cloud Storage Buckets. 2. Modify the ETL process to pick up files from these buckets.`},
    ],
    'answerId': [3],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3005,
    'question':`Consider the TerramEarth case study.
    TerramEarth would like to reduce unplanned downtime for all its vehicles and preemptively stock replacement parts. To do this, TerramEarth has partnered with another firm to loT enable all vehicles in the field. The telemetry data from vehicles is stored in the respective region buckets in the US, Asia and Europe. The feedback from most service centres and dealer networks indicates vehicle hydraulics fail after 69000 miles, and this has knock-on effects such as disabling the dynamic adjustment in the height of the vehicle. The vehicle design team has approached you to provide them with all raw telemetry data to analyze and determine the cause of this failure. You need to run this job on all the data. How should you do this while minimizing costs?
    
    `,
    'answers': [
        {'id':0, 'desc':`Transfer telemetry data from all Regional Cloud Storage buckets to another bucket in a single zone. Launch a Dataproc job in the same zone.`},
        {'id':1, 'desc':`Transfer telemetry data from all Regional Cloud Storage buckets to another bucket in a single region. Launch a Dataproc job in the same region.`},
        {'id':2, 'desc':`Run a Dataproc job in each region to extract, pre-process and tar (compress) the data. Transfer this data to a Multi-Regional Cloud Storage bucket. Launch a Dataprocjob.`},
        {'id':3, 'desc':`Run a Dataproc job in each region to extract, pre-process and tar (compress) the data. Transfer this data to a Regional Cloud Storage bucket. Launch a Dataproc job.`},
    ],
    'answerId': [3],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3006,
    'question':`TerramEarth would like to reduce unplanned downtime for all its vehicles and preemptively stock replacement parts. To do this, TerramEarth has partnered with another firm to loT enable all vehicles in the field. The CTO sees an Al-driven solution being the future of this prediction and wants to store all telemetry data in a cost-efficient way while the team works on building a blueprint for a machine learning model in a year. The CTO has asked you to facilitate cost-efficient storage of the telemetry data. Where should you store this data?`,
    'answers': [
        {'id':0, 'desc':`Compress the telemetry data in half-hourly snapshots on the vehicle loT device and push to a Nearline Google Cloud Storage bucket.`},
        {'id':1, 'desc':`Use a real-time (streaming) dataflow job to compress the incoming data and store in BigQuery.`},
        {'id':2, 'desc':`Use a real-time (streaming) dataflow job to compress the incoming data and store in Cloud Bigtable.`},
        {'id':3, 'desc':`Compress the telemetry data in half-hourly snapshots on the vehicle loT device and push to a Coldline Google Cloud Storage bucket.`},
    ],
    'answerId': [3],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3007,
    'question':`Consider the TerramEarth case study.
    The feedback from all TerramEarth service centres and dealer networks indicates vehicle hydraulics fail after 69000 miles, and this has knock-on effects such as disabling the dynamic adjustment in the height of the vehicle. The vehicle design team wants the raw data to be analyzed, and operational parameters tweaked in response to various factors to prevent such failures. How can you facilitate this feedback loop to all the connected and unconnected vehicles while minimizing costs?`,
    'answers': [
        {'id':0, 'desc':`Engineers from vehicle design team analyze the raw telemetry data and determine patterns that can be used by algorithms to identify operational adjustments and tweak the drive train parameters automatically.`},
        {'id':1, 'desc':`Use a custom machine learning solution in on-premises to identify operational adjustments and tweak the drive train parameters automatically.`},
        {'id':2, 'desc':`Run a real-time (streaming) Dataflow job to identify operational adjustments and use Firebase Cloud Messaging to push the optimisations automatically.`},
        {'id':3, 'desc':`Use Machine learning in Google Al Platform to identify operational adjustments and tweak the drive train parameters automatically.`},
    ],
    'answerId': [3],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3008,
    'question':`
    You analyzed TerramEarth’s business requirement to reduce downtime and found that they can achieve a majority of time saving by reducing customers’ wait time for parts. You decided to focus on reduction of the 3 weeks’ aggregate reporting time. Which modifications to the company’s processes should you recommend?`,
    'answers': [
        {'id':0, 'desc':`Migrate from CSV to binary format, migrate from FTP to SFTP transport, and develop machine learning analysis of metrics.`},
        {'id':1, 'desc':`Migrate from FTP to streaming transport, migrate from CSV to binary format, and develop machine learning analysis of metrics.`},
        {'id':2, 'desc':`Increase fleet cellular connectivity to 80%, migrate from FTP to streaming transport, and develop machine learning analysis of metrics.`},
        {'id':3, 'desc':`Migrate from FTP to SFTP transport, develop machine learning analysis of metrics, and increase dealer local inventory by a fixed factor.`},
    ],
    'answerId': [2],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3009,
    'question':`Which of TerramEarth’s legacy enterprise processes will experience significant change as a result of increased Google Cloud Platform adoption?`,
    'answers': [
        {'id':0, 'desc':`OpEx/CapEx allocation, LAN change management, capacity planning`},
        {'id':1, 'desc':`Capacity planning, TCO calculations, OpEx/CapEx allocation`},
        {'id':2, 'desc':`Capacity planning, utilization measurement, data center expansion`},
        {'id':3, 'desc':`Data center expansion,TCO calculations, utilization measurement`},
    ],
    'answerId': [1],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3010,
    'question':`Your agricultural division is experimenting with fully autonomous vehicles. You want your architecture to promote strong security during vehicle operation. Which two architecture characteristics should you consider? (choose two)`,
    'answers': [
        {'id':0, 'desc':`Use multiple connectivity subsystems for redundancy.`},
        {'id':1, 'desc':`Require IPv6 for connectivity to ensure a secure address space.`},
        {'id':2, 'desc':`Enclose the vehicle’s drive electronics in a Faraday cage to isolate chips.`},
        {'id':3, 'desc':`Use a functional programming language to isolate code execution cycles.`},
        {'id':4, 'desc':`Treat every microservice call between modules on the vehicle as untrusted.`},
        {'id':5, 'desc':`Use a Trusted Platform Module (TPM) and verify firmware and binaries on boot.`},
    ],
    'answerId': [4,5],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3011,
    'question':`Today, TerramEarth maintenance workers receive interactive performance graphs for the last 24 hours (86,400 events) by plugging their maintenance tablets into the vehicle. The support group wants support technicians to view this data remotely to help troubleshoot problems. You want to minimize the latency of graph loads. How should you provide this functionality?`,
    'answers': [
        {'id':0, 'desc':`Execute queries against data stored in a Cloud SQL.`},
        {'id':1, 'desc':`Execute queries against data indexed by vehicle_id.timestamp in Cloud Bigtable.`},
        {'id':2, 'desc':`Execute queries against data stored on daily partitioned BigQuery tables`},
        {'id':3, 'desc':`Execute queries against BigQuery with data stored in Cloud Storage via BigQuery federation.`},
    ],
    'answerId': [1],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3012,
    'question':`Because you do not know every possible future use for the data TerramEarth collects, you have decided to build a system that captures and stores all raw data in case you need it later. How can you most cost effectively accomplish this goal?`,
    'answers': [
        {'id':0, 'desc':`Have the vehicles in the field stream the data directly into BigQuery.`},
        {'id':1, 'desc':`Have the vehicles in the field pass the data to Cloud Pub/Sub and dump it into a Cloud Dataproc cluster that stores data in Apache Hadoop Distributed File System (HDFS) on persistent disks.`},
        {'id':2, 'desc':`Have the vehicles in the field continue to dump data via FTP, adjust the existing Linux machines, and use a collector to upload them into Cloud Dataproc HDFS for storage`},
        {'id':3, 'desc':`Have the vehicles in the field continue to dump data via FTP, and adjust the existing Linux machines to immediately upload it to Cloud Storage with gsutil.`},
    ],
    'answerId': [3],
    'rationale': [
        {'id':0, 'desc':`BigQuery requires structured data`},
        {'id':1, 'desc':`Possible, but there's no need to use dataproc - we don't know what the data is or how to process it`},
        {'id':2, 'desc':`The data dump will work, but there's no need to use dataproc - we don't know what the data is or how to process it`},
        {'id':3, 'desc':`This uploads it to Cloud Storage for later processing`},
    ]
},
{
    'id':3013,
    'question':`Terramearth decided to use BigQuery as Data warehouse solution. The data is expected to be very big (more than 20 TB per week) and they are looking at best possible ways to store and manage data. What is best possible configuration in BigQuery for this use case ?`,
    'answers': [
        {'id':0, 'desc':'Use federated data sets'},
        {'id':1, 'desc':'Use Cloud Storage to store data and load it whenever required'},
        {'id':2, 'desc':'Spread the data in multiple regions'},
        {'id':3, 'desc':'Use time based partition for dataset\'s tables'},
    ],
    'answerId': [3],
    'rationale': []
},
{
    'id':3014,
    'question':`TerramEarth wants to migrate to existing python based data ingestion implementation to Google Cloud Platform, What is right fit solution for transformation ?`,
    'answers': [
        {'id':0, 'desc':'Cloud Dataflow'},
        {'id':1, 'desc':'Cloud Dataproc'},
        {'id':2, 'desc':'Cloud BigQuery'},
    ],
    'answerId': [0],
    'rationale': []
},
{
    'id':3015,
    'question':`Consider the TerramEarth case study.
    TerramEarth wants to preemptively stock replacement parts and reduce the unplanned downtime of their vehicles to less than one week. The CTO sees an Al-driven solution being the future of this prediction. Still, for the time being, the planis to have the analysts carry out the analysis by querying all data from a central location and make predictions. Which of the below designs would give the analysts the ability to query data from a central location?`,
    'answers': [
        {'id':0, 'desc':`HTTP(s) Load Balancer, GKE on Anthos, Pub/Sub, Dataflow, BigQuery.`},
        {'id':1, 'desc':`HTTP(s) Load Balancer, GKE on Anthos, Dataflow, BigQuery.`},
        {'id':2, 'desc':`HTTP(s) Load Balancer, GKE on Anthos, BigQuery.`},
        {'id':3, 'desc':`App Engine Flexible, Pub/Sub, Dataflow, BigQuery.`},
        {'id':4, 'desc':`App Engine Flexible, Pub/Sub, Dataflow, Cloud SQL.`},
    ],
    'answerId': [0],
    'rationale': [
        {'id':0, 'desc':``},]
},
{
    'id':3016,
    'question':`Which of TerramEarth's legacy enterprise processes will experience significant change as a result of increased Google Cloud Platform adoption.`,
    'answers': [
        {'id':0, 'desc':'Opex/capex allocation, LAN changes, capacity planning'},
        {'id':1, 'desc':'Capacity planning, TCO calculations, opex/capex allocation'},
        {'id':2, 'desc':'Capacity planning, utilization measurement, data center expansion'},
        {'id':3, 'desc':'Data Center expansion, TCO calculations, utilization measurement'},
    ],
    'answerId': [1],
    'rationale': []
},
{
    'id':3017,
    'question':`Consider the TerramEarth case study. To speed up data retrieval, more vehicles will be upgraded to cellular connections and be able to transmit data to the ETL process. The current FTP process is error-prone and restarts the data transfer from the start of the file when connections fail, which happens often. You want to improve the reliability of the solution and minimize data transfer time on the cellular connections. What should you do?`,
    'answers': [
        {'id':0, 'desc':'Use one Google Container Engine cluster of FTP servers. Save the data to a Multi-Regional bucket. Run the ETL process using data in the bucket.'},
        {'id':1, 'desc':'Use multiple Google Container Engine clusters running FTP servers located in different regions. Save the data to Multi-Regional buckets in us, eu, and asia. Run the ETL process using the data in the bucket.'},
        {'id':2, 'desc':'Directly transfer the files to different Google Cloud Multi-Regional Storage bucket locations in us, eu, and asia using Google APIs over HTTP(S). Run the ETL process using the data in the bucket.'},
        {'id':3, 'desc':'Directly transfer the files to a different Google Cloud Regional Storage bucket location in us, eu, and asia using Google APIs over HTTP(S). Run the ETL process to retrieve the data from each Regional bucket.'},
    ],
    'answerId': [3],
    'rationale': []
},
{
    'id':3018,
    'question':`Your agricultural division is experimenting with fully autonomous vehicles.

    You want your architecture to promote strong security during vehicle operation.
    
    Which two architecture should you consider?
    
    Choose 2 answers`,
    'answers': [
        {'id':0, 'desc':'Treat every micro service call between modules on the vehicle as untrusted.'},
        {'id':1, 'desc':'Require IPv6 for connectivity to ensure a secure address space.'},
        {'id':2, 'desc':'Use a trusted platform module (TPM) and verify firmware and binaries on boot.'},
        {'id':3, 'desc':'Use a functional programming language to isolate code execution cycles.'},
        {'id':4, 'desc':'Use multiple connectivity subsystems for redundancy.'},
        {'id':5, 'desc':'Enclose the vehicle\'s drive electronics in a Faraday cage to isolate chips.'},
    ],
    'answerId': [0,2],
    'rationale': []
},
{
    'id':3019,
    'question':`TerramEarth's 20 million vehicles are scattered around the world. Based on the vehicle's location its telemetry data is stored in a Google Cloud Storage (GCS) regional bucket (US. Europe, or Asia). The CTO has asked you to run a report on the raw telemetry data to determine why vehicles are breaking down after 100 K miles. You want to run this job on all the data. What is the most cost-effective way to run this job?`,
    'answers': [
        {'id':0, 'desc':'Move all the data into 1 zone, then launch a Cloud Dataproc cluster to run the job.'},
        {'id':1, 'desc':'Move all the data into 1 region, then launch a Google Cloud Dataproc cluster to run the job.'},
        {'id':2, 'desc':'Launch a cluster in each region to preprocess and compress the raw data, then move the data into a multi region bucket and use a Dataproc cluster to finish the job.'},
        {'id':3, 'desc':'Launch a cluster in each region to preprocess and compress the raw data, then move the data into a region bucket and use a Cloud Dataproc cluster to finish the job'},
    ],
    'answerId': [3],
    'rationale': []
},
];

export const EHR = [
  {
      'id':4001,
      'question':`For this question, refer to the EHR Healthcare case study. You are responsible for ensuring that EHR's use of Google Cloud will pass an upcoming privacy compliance audit. What two things should you do?`,
      'answers': [
          {'id':0, 'desc':'Implement Prometheus to detect and prevent security breaches on EHR\'s web-based applications.'},
          {'id':1, 'desc':'Verify EHR\'s product usage against the list of compliant products on the Google Cloud compliance page.'},
          {'id':2, 'desc':'Use Firebase Authentication for EHR\'s user facing applications.'},
          {'id':3, 'desc':'Use GKE private clusters for all Kubernetes workloads.' },
          {'id':4, 'desc':'Advise EHR to execute a Business Associate Agreement (BAA) with Google Cloud'},
      ],
      'answerId': [1,4],
      'rationale': [
        {'id':1, 'desc':`Google Cloud compliance page will give list of products those are HIPAA compliant https://cloud.google.com/security/compliance/offerings?skip_cache=true#/regions=USA&industries=Healthcare_and_life_sciences&focusArea=Privacy`},
        {'id':4, 'desc':`BAA means HIPAA Business Associate amendment or Business Associate Agreement entered into between Google and Customer. With EHR being a leading provider of health record software, this agreement is required. https://cloud.google.com/files/gcp-hipaa-overview-guide.pdf?hl=en)`},
    ]
  },
  {
      'id':4002,
      'question':`For this question, refer to the EHR Healthcare case study. You need to define the technical architecture for securely deploying workloads to Google Cloud. You also need to ensure that only verified containers are deployed using Google Cloud services. What two things should you do?`,
      'answers': [
          {'id':0, 'desc':'Configure Jenkins to utilize Kritis to cryptographically sign a container as part of a CI/CD pipeline.'},
          {'id':1, 'desc':'Configure Container Registry to only allow trusted service accounts to create and deploy containers from the registry.'},
          {'id':2, 'desc':'Configure Container Registry to use vulnerability scanning to confirm that there are no vulnerabilities before deploying the workload'},
          {'id':3, 'desc':'Enable Binary Authorization on GKE and sign containers as part of a CI/CD pipeline'},
      ],
      'answerId': [1,3],
      'rationale': [{'id':3, 'desc':`Enable Binary Authorization on GKE, and sign containers as part of a CI/CD pipeline is a good option because Binary Authorization is a security feature that ensures that only verified containers are deployed to GKE clusters. By signing containers as part of a CI/CD pipeline, you can ensure that all containers deployed to GKE clusters are cryptographically signed, which allows Binary Authorization to verify the authenticity of the containers.`}]
  },
  {
  'id':4003,
  'question':`You need to upgrade the EHR connection to comply with their requirements. The new connection design must support business-critical needs and meet the same network and security policy requirements. What should you do?`,
  'answers': [
      {'id':0, 'desc':'Add a new Dedicated Interconnect connection.'},
      {'id':1, 'desc':'Upgrade the bandwidth on the Dedicated Interconnect connection to 100 G.'},
      {'id':2, 'desc':'Add three new Cloud VPN connections.'},
      {'id':3, 'desc':'Add a new Carrier Peering connection.'}
  ],
  'answerId': [0],
  'rationale': [{'id':0, 'desc':`Interconnect lets you establish high bandwidth, low latency connections between your Google Cloud VPC networks and your on-premises infrastructure.

    The case does not call out the throughput being an issue. However, to achieve 99.99%, you need to have 4 connections as per Google recommendations. However, in the options only A has the option to add an additional Interconnect connection.
    https://cloud.google.com/network-connectivity/docs/interconnect/concepts/dedicated-overview#availability
    
    https://cloud.google.com/network-connectivity/docs/interconnect/how-to/dedicated/modifying-interconnects`}]
},
{
    'id':4004,
    'question':`For this question, refer to the EHR Healthcare case study. You need to define the technical architecture for hybrid connectivity between EHR's on-premises systems and Google Cloud. You want to follow Google's recommended practices for production-level applications. Considering the EHR Healthcare business and technical requirements, what should you do?`,
    'answers': [
        {'id':0, 'desc':'Configure two Partner Interconnect connections in one metro (City), and make sure the Interconnect connections are placed in different metro zones.'},
        {'id':1, 'desc':'Configure two VPN connections from on-premises to Google Cloud, and make sure the VPN devices on-premises are in separate racks.]'},
        {'id':2, 'desc':'Configure Direct Peering between EHR Healthcare and Google Cloud, and make sure you are peering at least two Google locations.'},
        {'id':3, 'desc':'Configure two Dedicated Interconnect connections in one metro (City) and two connections in another metro, and make sure the Interconnect connections are placed in different metro zones'},
    ],
    'answerId': [3],
    'rationale': [{'id':3, 'desc':`Direct Connect EHR can get the bandwidth of 10 GBS to 100GBS (VPN ruled out as traffic is over internet and due to bandwidth. Direct Peering is more for Workspace rather than Google Cloud)

    https://cloud.google.com/network-connectivity/docs/interconnect/tutorials/partner-creating-999-availability
    https://cloud.google.com/network-connectivity/docs/interconnect/concepts/best-practices#scenarios`}]
  },
  {
    'id':4005,
    'question': `For this question, refer to the EHR Healthcare case study. You are a developer on the EHR customer portal team. Your team recently migrated the customer portal application to Google Cloud The load has increased on the application servers, and now the application is logging many timeout errors. You recently incorporated Pub/Sub into the application architecture, and the application is not logging any Pub/Sub publishing errors. You want to improve publishing latency. What should you do?`,
    'answers': [
        {'id':0, 'desc':'Increase the Pub/Sub Total Timeout retry value'},
        {'id':1, 'desc':'Move from a Pub/Sub subscriber pull model to a push model'},
        {'id':2, 'desc':'Turn off Pub/Sub message batching'},
        {'id':3, 'desc':'Create a backup Pub/Sub message queue.'},
    ],
    'answerId' : [2],
    'rationale': [{'id':2, 'desc':''}]
  },
  {
    'id':4006,
    'question': `For this question, refer to the EHR Healthcare case study. In the past, configuration errors put public IP addresses on backend servers that should not have been accessible from
    the Internet. You need to ensure that no one can put external IP addresses on backend Compute Engine instances and that external IP addresses can only be configured on frontend Compute Engine instances. What should you do?`,
    'answers': [
        {'id':0, 'desc':'Create an Organizational Policy with a constraint to allow external IP addresses only on the frontend Compute Engine instances'},
        {'id':1, 'desc':'Revoke the compute.networkAdmin role from all users in the project with front end instances'},
        {'id':2, 'desc':'Create an Identity and Access Management (1AM) policy that maps the IT staff to the compute.networkAdmin role for the organization'},
        {'id':3, 'desc':'Create a custom Identity and Access Management (1AM) role named GCE_FRONTEND with the compute.addresses.create permission.'},
    ],
    'answerId' : [0],
    'rationale': [{'id':0, 'desc':`Create an Organizational Policy with a constraint to allow external IP addresses only on the frontend Compute Engine instances.
    https://cloud.google.com/compute/docs/ip-addresses/reserve-static-external-ip-address#disableexternalip`}]
  },
  {
    'id':4007,
    'question': `For this question, refer to the EHR Healthcare case study. You are responsible for designing the Google Cloud network architecture for Google Kubernetes Engine. You want to
    follow Google best practices. Considering the EHR Healthcare business and technical requirements, what should you do to reduce the attack surface?`,
    'answers': [
        {'id':0, 'desc':'Use a private cluster with a private endpoint with master authorized networks configured'},
        {'id':1, 'desc':'Use a public cluster with firewall rules and Virtual Private Cloud (VPC) routes'},        
        {'id':2, 'desc':'Use a private cluster with a public endpoint with master authorized networks configured'},        
        {'id':3, 'desc':'Use a public cluster with master authorized networks enabled and firewall rules'},
    ],
  'answerId': [0],
  'rationale': [{'id':0, 'desc':`https://cloud.google.com/kubernetes-engine/docs/concepts/private-cluster-concept#overview. Public endpoint access disabled is the most secure option as it prevents all internet access to the control plane.
    This is a good choice if you have configured your on-premises network to connect to Google Cloud using Cloud Interconnect (EHR has enabled this) or Cloud VPN.
    If you disable public endpoint access, then you must configure authorized networks for the private endpoint. If you don’t do this, you can only connect to the private endpoint from cluster nodes or VMs in the same subnet as the cluster.
    Public endpoint access enabled, authorized networks enabled: This is a good choice if you need to administer the cluster from source networks that are not connected to your cluster’s VPC network using Cloud Interconnect or Cloud VPN (but EHR is already using interconnect) So answer C is wrong.
    Reference - https://cloud.google.com/kubernetes-engine/docs/concepts/private-cluster-concept`}]
  },
  {
    'id':4008,
    'question': `Your operations team currently stores 10 TB of data m an object storage service from a third-party provider. They want to move this data to a Cloud Storage bucket as quickly as possible, following Google-recommended practices. They want to minimize the cost of this data migration. When approach should they use?`,
    'answers': [
        {'id':0, 'desc':'Use the gsutil mv command lo move the data'},
        {'id':1, 'desc':'Use the Storage Transfer Service to move the data'},
        {'id':2, 'desc':'Download the data to a Transfer Appliance and ship it to Google'},
        {'id':3, 'desc':'Download the data to the on-premises data center and upload it to the Cloud Storage bucket'},
    ],
  'answerId': [1],
  'rationale': [{'id':1, 'desc':`Explanation:
  https://cloud.google.com/architecture/migration-to-google-cloud-transferring-your-large-datasets#transfer-options
  
  https://cloud.google.com/storage-transfer-service`}]
  }
];

export const HRL = [
  {
    'id':5000,
    'question':`For this question, refer to the Helicopter Racing League (HRL) case study. Your team is in charge of creating a payment card data vault for card numbers used to bill tens of thousands of viewers, merchandise consumers, and season ticket holders. You need to implement a custom card tokenization service that meets the following requirements:
    - It must provide low latency at minimal cost.
    - It must be able to identify duplicate credit cards and must not store plaintext card numbers.
    - It should support annual key rotation.
    Which storage approach should you adopt for your tokenization service?`,
    'answers': [
        {'id':0, 'desc':'Store the card data in Secret Manager after running a query to identify duplicates. '},
        {'id':1, 'desc':'Encrypt the card data with a deterministic algorithm stored in Firestore using Datastore mode. '},
        {'id':2, 'desc':'Encrypt the card data with a deterministic algorithm and shard it across multiple Memorystore instances. '},
        {'id':3, 'desc':'Use column-level encryption to store the data in Cloud SQL. '},
    ],
    'answerId': [3],
    'rationale': [{'id':3, 'desc':``}]
},
{
    'id':5001,
    'question':`For this question, refer to the Helicopter Racing League (HRL) case study. Recently HRL started a new regional racing league in Cape Town, South Africa. In an effort to give customers in Cape Town a better user experience, HRL has partnered with the Content Delivery Network provider, Fastly. HRL needs to allow traffic coming from all of the Fastly IP address ranges into their Virtual Private Cloud network (VPC network). You are a member of the HRL security team and you need to configure the update that will allow only the Fastly IP address ranges through the External HTTP(S) load balancer. Which command should you use?`,
    'answers': [
        {'id':0, 'desc':`gcloud compute security-policies rules update 1000 --security-policy from-fastly --src-ip-ranges * --action "allow"`},
        {'id':1, 'desc':'gcloud compute firewall rules update sourceiplist-fastly --priority 1000 --allow tcp:443'},
        {'id':2, 'desc':'gcloud compute firewall rules update hlr-policy --priority 1000 --allow tcp:443'},
        {'id':3, 'desc':'gcloud compute security-policies rules update 1000 --security-policy hlr-polict --expression "evaulatePreconfigureExpr(`sourceiplist-fastly`)" --action "allow"'},
    ],
    'answerId': [0],
    'rationale': [{'id':0, 'desc':`https://cloud.google.com/load-balancing/docs/https`}]
},
{
    'id':5002,
    'question':`For this question, refer to the Helicopter Racing League (HRL) case study. The HRL development team releases a new version of their predictive capability application every Tuesday evening at 3 a.m. UTC to a repository. The security team at HRL has developed an in-house penetration test Cloud Function called
    Airwolf. The security team wants to run Airwolf against the predictive capability application as soon as it is released every Tuesday. You need to set up Airwolf to run at the recurring weekly cadence. What should you do?`,
    'answers': [
        {'id':0, 'desc':'Set up Cloud Tasks and a Cloud Storage bucket that triggers a Cloud Function.'},
        {'id':1, 'desc':'Set up a Cloud Logging sink and a Cloud Storage bucket that triggers a Cloud Function.'},
        {'id':2, 'desc':'Configure the deployment job to notify a Pub/Sub queue that triggers a Cloud Function.'},
        {'id':3, 'desc':'Set up Identity and Access Management (IAM) and Confidential Computing to trigger a Cloud Function'},
    ],
    'answerId': [0],
    'rationale': [{'id':0, 'desc':``}]
},
{
    'id':5003,
    'question':`For this question, refer to the Helicopter Racing League (HRL) case study. HRL wants better prediction accuracy from their ML prediction models. They want you to use Googleג€™s AI Platform so HRL can understand and interpret the predictions. What should you do?`,
    'answers': [
        {'id':0, 'desc':'Use Explainable AI.'},        
        {'id':1, 'desc':'Use Vision AI.'},        
        {'id':2, 'desc':'Use Google Cloudג€™s operations suite.'},        
        {'id':3, 'desc':'Use Jupyter Notebooks.'},
    ],
    'answerId': [0],
    'rationale': [{'id':0, 'desc':`https://cloud.google.com/ai-platform/prediction/docs/ai-explanations/preparing-metadata`}]
},
{
    'id':5004,
    'question':`For this question, refer to the Helicopter Racing League (HRL) case study. HRL is looking for a cost-effective approach for storing their race data such as telemetry. They want to keep all historical records, train models using only the previous season's data, and plan for data growth in terms of volume and information collected. You need to propose a data solution. Considering HRL business requirements and the goals expressed by CEO S. Hawke, what should you do?`,
    'answers': [
        {'id':0, 'desc':'Use Firestore for its scalable and flexible document-based database. Use collections to aggregate race data by season and event.'},
        {'id':1, 'desc':'Use Cloud Spanner for its scalability and ability to version schemas with zero downtime. Split race data using season as a primary key.'},
        {'id':2, 'desc':'Use BigQuery for its scalability and ability to add columns to a schema. Partition race data based on season.'},
        {'id':3, 'desc':'Use Cloud SQL for its ability to automatically manage storage increases and compatibility with MySQL. Use separate database instances for each season.'},
    ],
    'answerId': [2],
    'rationale': [{'id':2, 'desc':`https://cloud.google.com/bigquery/public-data`}]
},
{
    'id':5005,
    'question':`For this question, refer to the Helicopter Racing League (HRL) case study. A recent finance audit of cloud infrastructure noted an exceptionally high number of
    Compute Engine instances are allocated to do video encoding and transcoding. You suspect that these Virtual Machines are zombie machines that were not deleted after their workloads completed. You need to quickly get a list of which VM instances are idle. What should you do?`,
    'answers': [
        {'id':0, 'desc':'Log into each Compute Engine instance and collect disk, CPU, memory, and network usage statistics for analysis.'},
        {'id':1, 'desc':'Use the gcloud compute instances list to list the virtual machine instances that have the idle: true label set.'},
        {'id':2, 'desc':'Use the gcloud recommender command to list the idle virtual machine instances.'},
        {'id':3, 'desc':' From the Google Console, identify which Compute Engine instances in the managed instance groups are no longer responding to health check probes. '},
    ],
    'answerId': [2],
    'rationale': [{'id':2, 'desc':`https://cloud.google.com/compute/docs/instances/viewing-and-applying-idle-vm-recommendations`}]
},
];


// {
//   'id':5006,
//   'question':``,
//   'answers': [
//       {'id':0, 'desc':'Set'},
//       {'id':1, 'desc':'Send'},
//       {'id':2, 'desc':'Use'},
//       {'id':3, 'desc':'Instrument'},
//   ],
//   'answerId': [3],
//   'rationale': [{'id':3, 'desc':``}]
// },